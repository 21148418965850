import Arrows from './Arrow.scss';

const CarouselNextArrow = ({ onClick }) => (
  <button className="arrow-button next" onClick={onClick} aria-label="Next Slide">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6l6 6-6 6" />
    </svg>
  </button>
);

export default CarouselNextArrow;
