import {Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {toPercent} from '../../../utils/number.util';
import "./DashboardPieChart.scss";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text className="bip-capital-dashboard-piechart-label" x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {toPercent(percent)}
    </text>
  );
};

export const DashboardPieChart = (props) => {
  const {data} = props;

  return (
    <div className="bip-capital-dashboard-pie-chart-card">
      <div className="bip-capital-dashboard-pie-chart-header">
        <h5>{data.title}</h5>
        <p>{data.description}</p>
      </div>
      <hr/> 
      <div className="bip-capital-dashboard-pie-chart-wrapper">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              isAnimationActive={false}
              dataKey="value"
              data={data.chartData}
              label={renderCustomizedLabel}
              labelLine={false}
            />
            <Legend/>
          </PieChart>
        </ResponsiveContainer>
      </div>

    </div>
  )
}