import { useContext, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { logUserAction, UserAction } from "../../../utils/logging.util";
import InputLabel from "../../Inputs/InputLabel/InputLabel";
import TextInput from "../../Inputs/TextInput/TextInput";
import { DistributionInstructionFieldData, DistributionInstructionFieldLabels } from "./DistributionInstructionDetail.model";

export const DistributionInstructionDetail = ({instruction}) => {
  const {userData} = useContext(UserContext);
  const fieldList = DistributionInstructionFieldData[instruction.type_id](instruction);

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.ViewDistributionInstructionDetail, instruction.id);
  }, [])

  return fieldList.map(f => (
    <div key={f}>
      <InputLabel forWhich={f} value={DistributionInstructionFieldLabels[f](instruction)} />
      <TextInput
        disabled
        id={f}
        name={f}
        value={instruction[f]}
      />
    </div>
  ))
}