const { InvestingEntityType } = require('../utils/constants');
/**
 * 
 * @param {number} investingEntityTypeId 
 * @returns 
 */
function isBdc(investingEntityTypeId) {
  return [InvestingEntityType.BDCEquity, InvestingEntityType.BDCDebt].includes(investingEntityTypeId);
}

module.exports = {
  isBdc
}