import "./EventSignUpButton.scss";
import { useContext, useState } from "react";
import { UIContext } from "../../../context/UIContext";
import { UserContext } from "../../../context/UserContext";
import { logUserAction, UserAction } from "../../../utils/logging.util";

/** URL for one off stop gap to support registration for 2024 shareholders meeting */
const shareholdersEventUrl = "https://bipcapital-5706307.hs-sites.com/shareholder2024";

export const EventSignUpButton = ({event, className}) => {
  const {setIsLoading} = useContext(UIContext);
  const {userData} = useContext(UserContext);

  const handleClick = async () => {
    // STOP-GAP: Open hyperlink to hubspot form url for 2024 shareholders meeting
    if (event.id === +process.env.REACT_APP_2024_SHAREHOLDERS_EVENT_ID) {
      return window.open(shareholdersEventUrl, "_blank", 'noopener,noreferrer');
    }
    else {
      window.open(event.registration_url, "_blank", 'noopener,noreferrer');
    }
    logUserAction(userData.contact_id, UserAction.ClickedEventSignUp, event.id);
  }

  return (
    <button className={`bip-capital-event-signup-button ${className || ""}`} onClick={handleClick}>Sign Up</button>
  );
}
  