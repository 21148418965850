import React, { useState, createContext } from "react";

export const DocumentsContext = createContext();

export const DocumentsProvider = (props) => {
  const [documents, setDocuments] = useState([]);
  const [entityPpms, setEntityPpms] = useState([]);

  return (
    <DocumentsContext.Provider value={{documents, setDocuments, entityPpms, setEntityPpms}}>
      {props.children}
    </DocumentsContext.Provider>
  );
};
