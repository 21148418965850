import { Col, Form } from "react-bootstrap";
import "./SelectionFilterGroup.scss";

export const SelectionFilterGroup = ({filters, setFilters, filterControlDef}) => {
  return (
    <div className="bip-capital-selection-filter-group">
      <Form.Row>
        {Object.entries(filterControlDef).map(([filterName, def]) => (
          <Col key={filterName} lg="auto" className="bip-capital-select-wrapper">
            <Form.Control 
              as="select" 
              value={filters[filterName]} 
              onChange={e => {
                const newFilters = {...filters};
                newFilters[filterName] = isNaN(+e.target.value) ? e.target.value : +e.target.value;
                setFilters(newFilters);
              }} 
            >
              {def.options.map((v, i) => <option key={i} value={def.valueFn ? def.valueFn(v, i) : v}>{def.labelFn(v)}</option>)}
            </Form.Control>
          </Col>
        ))}
      </Form.Row>
    </div>
  )
}