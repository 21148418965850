import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { setUserData, setAuthenticated, authenticated } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getStoredUserData = async () => {
      const storedUserData = JSON.parse(localStorage.getItem("userData"));

      if (!storedUserData) {
        setIsLoading(false);
        return;
      }

      axios.defaults.headers.common["accesstoken"] = storedUserData.jwt;
      try {
        const dbUserData = await axios.post(`/users`, {
          cognito_id: storedUserData.cognito_id,
        });
  
        delete storedUserData.jwt;
  
        setUserData({
          ...storedUserData,
          ...dbUserData.data,
        });
  
        setAuthenticated(true);
      } finally {
        setIsLoading(false);
      }
    };

    getStoredUserData();
  }, []);

  return (
    <Route
      {...rest}
      render={() => {
        if (isLoading) {
          return <p>Loading...</p>
        }
        return (authenticated ? <Component /> : <Redirect to="/login" />)
      }}
    />
  );
};

export default AuthenticatedRoute;
