import React, { useState } from "react";

import Container from "../../components/Views/Container/Container";
import LoginForm from "../../components/Forms/LoginForm/LoginForm";
import MFAForm from "../../components/Forms/MFAForm/MFAForm";
import SetPasswordForm from "../../components/Forms/SetPasswordForm/SetPasswordForm";

export default function LoginPage() {
  const [currentForm, setCurrentForm] = useState(1);

  return (
    <div className="unauthenticate-wrapper">
      <Container>
        {currentForm === 1 && <LoginForm setCurrentForm={setCurrentForm} />}
        {currentForm === 2 && <MFAForm origin="login" />}
        {currentForm === 3 && (
          <SetPasswordForm setCurrentForm={setCurrentForm} />
        )}
      </Container>
    </div>
  );
}
