import React from "react";

import "./TextInput.css";

const TextInput = ({ name, onChange, value, type = "text", disabled = false }) => (
  <input
    type={type}
    name={name}
    onChange={onChange}
    value={value}
    className="Text-input"
    disabled={disabled}
  />
);

export default TextInput;
