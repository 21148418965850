/**
 * Partition an array of elements into two arrays that pass/fail a given condition, 
 * or into two arrays that pass a first condition and second optional condition
 * @param {Array} arr The array to partition
 * @param {Function} predicateA A function invoked on each element that returns truthy/falsey
 * @param {Function?} predicateB Optional second function if splitting array into arrays that pass each predicate respectively
 * @returns {Array} A tuple of partitioned arrays
 */
export function partition(arr, predicateA, predicateB = null) {
  const [partitionA, partitionB] = [[], []];

  for (const e of arr) {
    if (predicateA(e)) {
      partitionA.push(e);
    } else if (!predicateB || predicateB(e)) {
      partitionB.push(e)
    }
  }
  return [partitionA, partitionB];
}

/**
 * Group an array of elements by a key returned by a provided function
 * @param {Array} arr The array to group into an object 
 * @param {Function} groupingFn Function that returns a value to group similar array elements by
 * @returns {object} An object where each key is a value returned by the grouping function
 */
export function groupBy(arr, groupingFn) {
  return arr.reduce((result, current) => {
    const key = groupingFn(current);
    const hasAddedKey = !!result[key];
    hasAddedKey ? result[key].push(current) : (result[key] = [current]);
    return result;
  }, {})
}

/**
 * Sum elements of an array
 * @param {Array} arr 
 * @param {string} key (optional) Element's property to sum
 * @todo Consider replacing key with a function
 * @returns {number} Sum of array elements or property of array elements
 */
export function sumElements(arr, key = null) {
  const sum = arr.reduce((acc, prev) => (key ? prev[key] ?? 0 : prev) + acc, 0);
  return sum;
}

/**
 * Paginates an array for the given page number and page size
 * @param {Array} arr 
 * @param {number} pageSize 
 * @param {number} pageNumber 
 * @returns 
 */
export function paginate(arr, pageSize, pageNumber) {
  const result = arr.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  return result;
}

export function createObjectMap(arr, key, valueFn = null) {
  const map = {};
  for (const element of arr) {
    map[element[key]] = valueFn ? valueFn(element) : element;
  }
  return map;
}

export const getDistinctItemsById = (items) => {
  const seenIds = new Set();
  return items.filter((item) => {
    if (seenIds.has(item.id)) return false;
    seenIds.add(item.id);
    return true;
  });
};
