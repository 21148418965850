import axios from "axios";
import { useEffect, useState, useContext, useMemo } from "react";
import { groupBy } from "../../utils/array.util";
import { UserContext } from "../../context/UserContext"
import { toCurrency } from "../../utils/number.util";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from "react-bootstrap";
import { DataTable } from "../../components/DataTable/DataTable";
import { UIContext } from "../../context/UIContext";
import { downloadBlob } from "../../utils/file.util";
import { logUserAction } from "../../utils/logging.util";
import { handleTokenExpireError } from "../../utils/misc.util";
import { UserAction } from "../../utils/logging.util";
import './K1Checklist.scss';

const displayCapitalFn = (v) => {
    return (
        <span style={{ color: '#F9E4B4' }}>
            {`${toCurrency(v)}`}
        </span>
    );
};


const displayEntityName = (v, row) => {
    return (
        <span style={{ display: "flex" }}>
            <Form.Check custom defaultChecked={!!row.document_id} />
            {v}
        </span>
    )
}

const k1checklistColumnDef = [
    { id: 'legal_name', label: "Entities", displayFn: displayEntityName },
    { id: 'commitment', label: "Committed Amount", displayFn: displayCapitalFn, },
];

const currentYear = new Date().getFullYear()

export const K1CheckListpage = () => {
    const { userData } = useContext(UserContext);
    const [checkListState, setCheckListState] = useState([])
    const [childrenData, setChildrenData] = useState([])
    const { setIsLoading, setErrorMessage } = useContext(UIContext);

    const init = async () => {
        const { data } = await axios.get(`/documents/k1checklist/${userData?.customer_id}`)
        const {regD, bdc} = data;
        const allInvestments = [...regD, ...bdc];
        const docsByAccount = groupBy(allInvestments, d => d.account_id)
        const dataSource = Object.entries(docsByAccount).map(([accountId, docs]) => ({
            id: accountId,
            name: docs[0].account_name,
            children: docs
        }));
        setCheckListState(dataSource)
        setChildrenData(allInvestments)
    }

    useEffect(() => {
        init()
    }, [])

    const isDocAvailable = useMemo(() => (childrenData?.some((c => !!c.document_id))), [childrenData])

    const handleDownload = async () => {
        try {
            setIsLoading(true);
            const ids = childrenData?.filter((c => !!c.document_id)).map((c => c.document_id));
            const blob = (await axios.post('/documents/download', { ids }, { responseType: 'blob' })).data;
            downloadBlob(blob, `K1Checklist.zip`);
            logUserAction(userData.contact_id, UserAction.K1ChecklistDownload);
        } catch (error) {
            setErrorMessage("Error while downloading file");
            handleTokenExpireError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container className="bip-capital-k1-page">
            <div style={{ marginLeft: "20px" }}>
              <h1 style={{paddingBottom: "1%"}}>{`${ currentYear -1 } Scheduled K-1 Forms`}</h1>
              <p>Below are Schedule K-1 Tax Forms for the {`${currentYear - 1}`} tax year. When a Schedule K-1 has been published and ready to be viewed/downloaded, you'll see a checkmark in the box next to that investment account. Boxes without a checkmark are not yet available.</p>
            </div>
            <div style={{ mt: 5, display: "flex", justifyContent: "flex-end" }}>
                {childrenData?.length > 0 ?
                    <Button style={{ backgroundColor: isDocAvailable ? '#F9E4B4' : ' #ccc', border: "none" , color: isDocAvailable ? '#000000': '#fff'}} onClick={handleDownload} disabled={!isDocAvailable}>Download All</Button>
                    : ''}
            </div>
            {checkListState?.map((checklist, index) => (
                <div key={index} style={{padding: "1%"}}>
                    <h4 style={{ padding: "1%" }}>{checklist.name}</h4>
                    <Row>
                        <Col>
                            <Container>
                                <DataTable
                                    dataSource={checklist?.children?.map(((c) => ({ ...c, id: `${c.investment_company_id}-${c.account_id}` })))}
                                    columnDef={k1checklistColumnDef}
                                />
                            </Container>
                        </Col>
                    </Row>
                </div>

            ))}
        </Container>
    )
}