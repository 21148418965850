import React from "react";

import ClipLoader from "react-spinners/ClipLoader";

import "./PrimaryButton.css";

/**
 * 
 * @param {{
 * text: string,
 * disabled?: boolean,
 * isLoading?: boolean
 * onClick: (e: unknown) => void
 * }} props
 * @returns 
 */
const PrimaryButton = ({ text, disabled, isLoading, onClick }) => (
  <button
    type="submit"
    className={`Primary-button${disabled ? "-disabled" : ""}`}
    disabled={disabled}
    onClick={onClick}
  >
    {!isLoading && text}
    {isLoading && (
      <ClipLoader color={"#171f27"} loading={isLoading} size={10} />
    )}
  </button>
);

export default PrimaryButton;
