export const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
}

export const downloadBlob = (blob, fileName) => {
  const url = URL.createObjectURL(blob);
  downloadFile(url, fileName);
  //Schedule to revoke object URL in next task just to make sure it occurs after the download click event
  setTimeout(() => URL.revokeObjectURL(url));
}