import { Card } from "react-bootstrap";
import { CashFlow } from "../../utils/constants";
import { formatDate } from "../../utils/date.util";
import { toCurrency } from "../../utils/number.util";
import capitalCallIcon from "../../assets/icon-capital-call.svg";
import distributionIcon from "../../assets/icon-distribution.svg";
import "./CashFlowCard.scss"

const dateFormatOptions = {month: "short", day: "2-digit"};

export const CashFlowCard = (props) => {
  const {cashFlow} = props;
  const isCapitalCall = cashFlow.cash_flow_type_id === CashFlow.CapitalCall
  const icon = isCapitalCall ? capitalCallIcon : distributionIcon;
  const altText = isCapitalCall ? "Capital Call icon" : "Distribution icon"

  return (
    <div className="bip-capital-cc-cash-flow-card">
      <Card>
        <Card.Body>
          <Card.Title>{formatDate(cashFlow.date, dateFormatOptions)} <img src={icon} alt={altText}/></Card.Title>
          {toCurrency(cashFlow.amount)}
          <div className="bip-capital-cc-cash-flow-card-names">
            <span>{cashFlow.title}</span>
            <span>{cashFlow.investmentName}</span>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}