import React, { useEffect, useState, Fragment} from "react";
import { Pagination, Table } from "react-bootstrap";
import { createObjectMap, paginate, sumElements } from "../../utils/array.util";
import "./DataTable.scss";

const getTableData = (columnDef, data) => {
  return columnDef.map(column => (
    <td key={column.id} className={`bip-capital-datatable-col-${column.id}`}>
      {column.displayFn ? column.displayFn(data[column.id], data) : data[column.id]}
    </td>
  ))
}

export const MAX_ROWS = 5;

export const DataTable = (props) => {
  const {showTotalsFooter, showPaginator, columnDef, dataSource, headerComponent} = props;
  const [displayedRows, setDisplayedRows] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => { 
    let rows = dataSource;
    
    if (showPaginator) {
      setActivePage(1);
      const range = Math.ceil(dataSource.length / MAX_ROWS);
      const pageNumbers = Array.from({length: range}, (_, i) => i + 1 );
      setPageNumbers(pageNumbers);
      rows = paginate(dataSource, MAX_ROWS, 1);
    }
    setDisplayedRows(rows);
    
    const isExpandable = !!rows[0]?.expandable;
    if (isExpandable) {
      const initialExpandedRows = createObjectMap(rows, 'id', row => row.isInitiallyExpanded ?? true);
      setExpandedRows(initialExpandedRows);
    }

  }, [dataSource])

  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
    setDisplayedRows(paginate(dataSource, MAX_ROWS, pageNumber));
  }

  let tableFooter = null;
  
  if (showTotalsFooter || showPaginator) {
    const footerRows = [];
    
    if (showTotalsFooter) {
      const [_first, ...rest] = columnDef
    
      const totalsData = rest.map(column => {
        const {id, showTotal, totalFn, displayFn } = column;
        const total = showTotal ? totalFn ? totalFn(dataSource) : sumElements(dataSource, id) : "";
          
        const displayedTotal = showTotal && displayFn ? displayFn(total) : total;
        return <td key={column.id}>{displayedTotal}</td>;
      });
      const footerData = [<td key="total">TOTAL</td>, ...totalsData];
      footerRows.push(footerData)
    }

    if (showPaginator && dataSource.length > MAX_ROWS) {
      const paginatorItems = pageNumbers.map(number => (
        <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageClick(number)}>
          {number}
        </Pagination.Item>
      ));
      footerRows.push(<td colSpan={columnDef.length}><Pagination size="md">{paginatorItems}</Pagination></td>);
    }


    tableFooter = (
      <tfoot className="bip-capital-cc-datatable-footer">
        {footerRows.map((data, i) => <tr key={i}>{data}</tr>)}
      </tfoot>
    )
  }

  const handleExpansionClick = (id) => {
    const newExpandedRows = {...expandedRows};
    newExpandedRows[id] = !expandedRows[id];
    setExpandedRows(newExpandedRows);
  }

  const isExpanded = id => expandedRows[id];

  return (
    <div className={`bip-capital-cc-datatable-wrapper ${displayedRows[0]?.expandable ? 'bip-capital-datatable-grouped' : ''}`}>
      {headerComponent && <div className="bip-capital-cc-datatable-header table-dark">{headerComponent}</div>}
      <Table striped variant="dark" responsive>
        <thead className="bip-capital-datatable-header">
          <tr>
            {columnDef.map(column => <th key={column.id} className={`bip-capital-datatable-col-${column.id}`}>{column.label}</th>)}
          </tr>
        </thead>
        <tbody>
          {displayedRows.map((data, i) => (
            <Fragment key={data.id ?? i}>
              <tr>
                {!!data.expandable 
                  ? <td className="bip-capital-datatable-group-header" colSpan={columnDef.length} onClick={() => handleExpansionClick(data.id)}>
                      <i className={`fas fa-caret-right ${isExpanded(data.id) ? 'expanded' : ""}`}></i> 
                      {data.name}
                    </td> 
                  : getTableData(columnDef, data)}
              </tr>
              { !!data.expandable &&
                data.children.map((child, childIndex) =>
                  <tr key={child.id ?? childIndex} className={`collapse ${isExpanded(data.id) ? "show" : ""}`}>
                    {getTableData(columnDef, child)}
                  </tr>
                )
              }
            </Fragment>
          ))}
        </tbody>
        {!!tableFooter && tableFooter}
      </Table>
    </div>
  ) 
}