import { useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { UIContext } from "../../context/UIContext";
import "./SpinnerOverlay.scss";

export const SpinnerOverlay = () => {
  const { isLoading } = useContext(UIContext);

  return (
    <Modal show={isLoading} centered dialogClassName="bip-capital-spinner-overlay" animation={false} backdrop="static">
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </Modal>
  );
}