import React, { Fragment, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import Rectangle from "../../Views/Rectangle/Rectangle";
import Logo from "../../Logo/Logo";
import InputLabel from "../../Inputs/InputLabel/InputLabel";
import TextInput from "../../Inputs/TextInput/TextInput";
import ErrorLabel from "../../Inputs/ErrorLabel/ErrorLabel";
import PasswordInput from "../../Inputs/PasswordInput/PasswordInput";
import PrimaryButton from "../../Inputs/PrimaryButton/PrimaryButton";
import { logUserAction, UserAction } from "../../../utils/logging.util";
import { TermsOfServiceUrl } from "../../../utils/misc.util";
import "./LoginForm.css";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  password: Yup.string().label("Password").required(),
});

const LoginForm = ({ setCurrentForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setCognitoUser, userData, setUserData, setAuthenticated } =
    useContext(UserContext);

  const login = async (email, password, setSubmitting) => {
    try {
      setIsLoading(true);

      const user = await Auth.signIn(email, password);

      setCognitoUser(user);
      setUserData({
        ...userData,
        email,
      });

      setIsLoading(false);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setCurrentForm(3);
        return;
      } else if (user.challengeName === "SMS_MFA") {
        setCurrentForm(2);
        return;
      } else {
        const jwt = user.signInUserSession.accessToken.jwtToken;

        axios.defaults.headers.common["accesstoken"] = jwt;

        const dbUserData = await axios.post(`/users`, {
          cognito_id: user.username,
        });

        setUserData({
          cognito_id: user.username,
          ...userData,
          ...dbUserData.data,
        });

        localStorage.setItem(
          "userData",
          JSON.stringify({
            cognito_id: user.username,
            ...userData,
            ...dbUserData.data,
            jwt,
          })
        );

        setAuthenticated(true);
        logUserAction(dbUserData.data.contact_id, UserAction.LoggedIn);
      }
    } catch (err) {
      setSubmitting(false);
      console.log(err);
      setIsLoading(false);
      alert("Try again, invalid username or password");
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit(values) {
      login(values.email, values.password, setSubmitting);
    },
  });

  return (
    <Fragment>
      <Logo />
      <Rectangle>
        <form onSubmit={handleSubmit}>
          <p className="Log-in">Log in</p>
          <InputLabel forWhich="email" value="Email" />
          <TextInput
            name="email"
            onChange={handleChange}
            value={values.email}
          />
          <ErrorLabel
            showError={touched.email && errors.email}
            error={errors.email}
          />
          <InputLabel forWhich="password" value="Password" />
          <PasswordInput
            name="password"
            onChange={handleChange}
            value={values.password}
          />
          <ErrorLabel
            showError={touched.password && errors.password}
            error={errors.password}
          />
          <div className="Forgot-password">
            <p className="Forgot-password-text">
              <Link to="/reset">Forgot Password</Link>
            </p>
          </div>
          <div className="btn-wrapper">
            <PrimaryButton
              text="Log In"
              disabled={!isValid || isSubmitting}
              isLoading={isLoading}
            />
          </div>
          <div className="terms-of-use" style={{lineHeight: '1em'}}>
            <small style={{fontSize: '11px'}}>Your use of this investor portal is subject to our <a href={TermsOfServiceUrl}>Terms of Use and Disclosures</a></small>
          </div>
        </form>
      </Rectangle>
    </Fragment>
  );
};

export default LoginForm;
