import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NewsContext } from "../../context/NewsContext";
import { UserContext } from "../../context/UserContext";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { TermsOfServiceUrl } from "../../utils/misc.util";
import { NewsPageCard } from "./NewsPageCard/NewsPageCard";

export const NewsPage = () => {
  const { news } = useContext(NewsContext);
  const {userData} = useContext(UserContext);

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.NewsPage);
  }, [])

  return (
    <Container>
      <Row>
        <Col lg="9">
          <h1
            style={{ position: "relative", bottom: "1em", display: "inline" }}
          >
            GP Updates
          </h1>
          <p>
            This is where you can find articles that relate to your current investments.
            Information included in any GP updates may contain forward-looking statements and is based on information provided by the applicable portfolio company.  Please see our <a href={TermsOfServiceUrl}>Terms of Use and Disclosures</a> for important disclosures regarding such information.
          </p>
          {news.map((n) => (
            <NewsPageCard key={n.id} news={n} className="mb-5" />
          ))}
        </Col>
      </Row>
    </Container>
  );
};
