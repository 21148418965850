import { Chip } from "../Chip/Chip"
import "./ChipToggleGroup.scss";

export const ChipToggleGroup = (props) => {
  const {onClick, activeState, chipList} = props;
  const chipProps = {onClick, activeState}

  return (
    <div className="bip-capital-cc-chip-toggle-group">
      {chipList.map(c => <Chip value={c.value} key={c.value} {...chipProps}>{c.label}</Chip>)}
    </div>
  )
}