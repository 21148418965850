import { useEffect, useMemo, useState } from "react"
import { groupBy } from "../../utils/array.util";
import { getCurrentDate } from "../../utils/date.util";
import { DataTable, MAX_ROWS } from "../DataTable/DataTable";
import { SelectionFilterGroup } from "../SeletionFilterGroup/SelectionFilterGroup";
import { getColumnDef, getAccountFilterControlDef } from "./CashFlowsTable.util";
import "./CashFlowsTable.scss";

export const CashFlowsTable = (props) => {
  const {investments, accounts, accountsMap, className, companyId} = props;
  const [masterDataSource, setMasterDataSource] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  const [filters, setFilters] = useState({
    investmentFilterId: 0, 
    accountFilterId: 0, 
    typeFilterId: 0,
    daysFilter: 365
  });
  
  useEffect(() => {
    const cashFlows = investments?.flatMap(i => i.cash_flows.map(
      cf => ({...cf, investment: i.investment_name, companyId: i.investment_company_id, accountId: i.account_id}) ?? [])
    );
    
    let dataSource = cashFlows.filter(cf => new Date(cf.date).getTime() < getCurrentDate().getTime());
    if (companyId) {
      dataSource = dataSource.filter(d => d.companyId === +companyId);
    }
    setMasterDataSource(dataSource);

    if (accounts) {
      const accountIds = dataSource.map(d => d.accountId);
      setFilteredAccounts(accounts.filter(a => accountIds.includes(a.id)));
      setFilters(filters => ({ ...filters, investmentFilterId: 0 }))
    } else {
      setFilteredAccounts([]);
      setFilters(filters => ({ ...filters, accountFilterId: 0 }))
    }

  }, [investments, companyId, accounts])

  const investmentCompanies = useMemo(() => {
    const grouped = groupBy(investments, i => i.investment_company_id)
    return Object.values(grouped).map(g => ({id: g[0].investment_company_id, name: g[0].investment_name }));
  }, [investments]);
  
  const dateMilliseconds = new Date().setDate(getCurrentDate().getDate() - filters.daysFilter);
  let dataSource = filters.daysFilter > -1 ? masterDataSource.filter(cf => new Date(cf.date).getTime() >= dateMilliseconds) : [...masterDataSource];

  if (filters.investmentFilterId || filters.typeFilterId || filters.accountFilterId) {
    dataSource = dataSource.filter(row => {
      const isSelectedInvestment = !filters.investmentFilterId || (row.companyId === filters.investmentFilterId);
      const isSelectedAccount = !filters.accountFilterId || row.accountId === filters.accountFilterId;
      const isSelectedType = !filters.typeFilterId || (row.cash_flow_type_id === filters.typeFilterId)
      return isSelectedInvestment && isSelectedType && isSelectedAccount;
    });
  }


  const filterControlDef = getAccountFilterControlDef(investmentCompanies, filteredAccounts)
  const filterGroupProps = {filters, setFilters, filterControlDef};

  return (
    <div className={className ?? ""}>
      <h5 className="mb-3">Recent Capital Calls / Distributions</h5>
      <DataTable
        columnDef={getColumnDef(accountsMap)}
        dataSource={dataSource}
        showPaginator={dataSource.length > MAX_ROWS}
        headerComponent={<SelectionFilterGroup {...filterGroupProps}/>}
      />
    </div>
  )
}