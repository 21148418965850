import React, { useState, createContext , useContext} from "react";

export const InvestmentsContext = createContext();

export const AppProvider = (props) => {
  const [investments, setInvestments] = useState([]);
  const [bdcInvestments, setBdcInvestments] = useState([]);

  const [investmentsMap, setInvestmentsMap] = useState({});
  const [bdcInvestmentsMap, setBdcInvestmentsMap] = useState({});

  const [cashFlows, setCashFlows] = useState([]);
  const [upcomingCashFlows, setUpcomingCashFlows] = useState([]);

  const [investmentsByCompany, setInvestmentsByCompany] = useState({});
  const [investmentsByAccount, setInvestmentsByAccount] = useState({});
  const [investmentValueTotals, setInvestmentValueTotals] = useState({});

  const [interestList, setInterestList] = useState([]);
  const [bdcInterestList, setBdcInterestList] = useState([]);

  return (
    <InvestmentsContext.Provider
      value={{
        investments,
        setInvestments,
        investmentsMap,
        setInvestmentsMap,
        cashFlows,
        setCashFlows,
        upcomingCashFlows,
        setUpcomingCashFlows,
        investmentsByCompany,
        setInvestmentsByCompany,
        investmentsByAccount,
        setInvestmentsByAccount,
        investmentValueTotals,
        setInvestmentValueTotals,
        interestList,
        setInterestList,
        bdcInvestments,
        setBdcInvestments,
        bdcInvestmentsMap,
        setBdcInvestmentsMap,
        bdcInterestList,
        setBdcInterestList,
      }}
    >
      {props.children}
    </InvestmentsContext.Provider>
  );
};

export const useInvestments = () => {
  const context = useContext(InvestmentsContext);

  if (!context) {
    throw new Error("useInvestments must be used within an InvestmentsProvider");
  }

  return context;
}