import { DistributionInstructionType, WireType } from "../../../utils/constants"

const DistributionInstructionAddressFields = [
  "street_address_1",
  "street_address_2",
  "city",
  "state",
  "zip_code"
]

export const DistributionInstructionFieldData = {
  [DistributionInstructionType.Wire]: (instruction) => [
    "wire_type",
    "recipient_name",
    ...(instruction.wire_type_id === WireType.Domestic ? ["aba"] : ["swift"]),
    "account_number",
    "account_name",
    "account_type",
    "ffc_to",
    "ffc_account_number",
    ...DistributionInstructionAddressFields,
  ],
  [DistributionInstructionType.Brokerage]: (instruction) => [
    "wire_type",
    "recipient_name",
    ...(instruction.wire_type_id === WireType.Domestic ? ["aba"] : ["swift"]),
    "account_number",
    "account_name",
    "account_type",
    "ffc_to",
    "ffc_account_number",
    ...DistributionInstructionAddressFields,
  ],
  [DistributionInstructionType.Check]: () => [
    "recipient_name",
    ...DistributionInstructionAddressFields
  ],
  [DistributionInstructionType.ACH]: () => [
    "account_name",
    "aba",
    "account_number",
    "account_type",
    "ffc_to",
  ],
} 

export const DistributionInstructionFieldLabels = {
  wire_type: () => "Type of Wire",
  recipient_name: (instruction) => instruction.type_id === DistributionInstructionType.Wire ? "Investor Name" : "Payee Name",
  aba: () => "ABA Routing Number",
  swift: () => "SWIFT Code",
  account_number: () => "Account Number / IBAN",
  account_name: () => "Bank Account Name",
  account_type: () => "Bank Account Type",
  ffc_to: () => "For Further Credit to",
  ffc_account_number: () => "FFC to Account Number",
  street_address_1: () => "Address",
  street_address_2: () => "Address 2", 
  city: () => "City",
  state: () => "State",
  zip_code: () => "ZIP Code"
}