import React, { Fragment } from "react";

import "./ErrorLabel.css";

export default function ErrorLabel({ showError, error }) {
  return (
    <Fragment>
      {showError && <p className="Form-error-label">{error}</p>}
    </Fragment>
  );
}
