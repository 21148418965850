import ProgressBar from 'react-bootstrap/ProgressBar';
import {FileText} from 'react-feather';
import {Link} from 'react-router-dom';
import './InvestmentInProgressCard.scss';

const InvestmentInProgressCard = ({investment, index, investmentsCount}) => (
  <div className="investment-card">
    <div className="card-header">
      <span className="active-enrollments">
        {`${index + 1} of ${investmentsCount} active enrollment${investmentsCount > 1 ? 's' : ''}`}
      </span>
      <Link className="view-detail" to={`/opportunities/${investment.investment_company_id}`}>View Detail</Link>
    </div>
    <div className="enrollment-status">
      <span className="badge-status">
        <FileText size={16} className="mr-1" /> Enrollment In Progress
      </span>
    </div>
    <div className="card-body">
      <img src={investment.mobile_logo_url} alt="Investment Logo" className="investment-logo" />
      <div className="investment-details">
        <h5>{investment.investment_company_name}</h5>
        <p>{investment.account_name || '*Discuss with Advisor'}</p>
      </div>
    </div>
    <div className="card-footer">
      <strong className="commitment-amount">{`$${investment.commitment_amount.toLocaleString()}`}</strong>
      <div className="progress-section">
        <span className="completion-text">{`${Math.round(investment.completion * 100)}% Complete`}</span>
        <ProgressBar now={investment.completion * 100} className="custom-progress-bar" />
      </div>
    </div>
  </div>
);

export default InvestmentInProgressCard;
