import React from "react";

import logo from "../../assets/BIPCV-White-LRG.png";

import "./Logo.css";

const Logo = () => (
  <div className="Logo">
    <img 
    style={{width: '10rem'}} 
    src={logo} alt="Logo" />
  </div>
);

export default Logo;
