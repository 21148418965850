import "./ShareholdersPage.scss";
import { useContext, useEffect } from "react"
import { UIContext } from "../../context/UIContext";
import axios from "axios";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { UserContext } from "../../context/UserContext";
import { handleTokenExpireError } from "../../utils/misc.util";
import { ShareholdersContext } from "../../context/ShareholdersContext";

export const ShareholdersPage = () => {
  const { userData } = useContext(UserContext);
  const {setIsLoading, setErrorMessage} = useContext(UIContext);
  const {shareholdersData} = useContext(ShareholdersContext)
  

  const handleLinkClick = async (event, document_id) => {
    event.preventDefault();
    try {
      setIsLoading(true);
  
      const link = (await axios.get(`/documents/download/${document_id}`)).data;
      window.open(link.url, '_blank');
    } catch (error) {
      setErrorMessage("Error while downloading file");
      handleTokenExpireError(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.ViewShareholdersPage);
  }, [])

  return (
    <>
      <div className="bip-capital-shareholders-page">
        <div className="image-container">
          <img src="/images/shareholders2024.jpg" alt="bip-capital-shareholders-2024" className="shareholders-image"/>
        </div>
        <div className="content-container">
          <h3>Schedule</h3>
          <a href='/Shareholders Meeting Agenda 2024.pdf' target='_blank' rel='noopener noreferrer'>View</a>
          <p>
            The 2024 Shareholders Meeting held on May 2nd offered a half-day of networking and portfolio progress updates. 
            Presentations covered the Opportunities Fund and Opportunities Fund II, Growth Fund III, Early Stage Fund, 
            Venture Fund IV, Venture Fund 5, and BIP Capital Evergreen BDC.
          </p>
          {shareholdersData.map((data) => (
            <div key={data.document_id}>
             {data.file_name && 
              <> 
                <a href="/" onClick={(e) => handleLinkClick(e,data.document_id)}>
                {data.file_name}
                </a>
                <p>
                  {data.description ?? ""}
                </p>
              </>}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

