import {useState} from 'react';
import TextInput from '../TextInput/TextInput';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import './PasswordInput.css';

/** Password input with a visibility toggle */
const PasswordInput = ({value, name, onChange}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleClick = () => setShowPassword((value) => !value); 

  return (
    <div className='password-input-wrapper'>
      <TextInput
        type={showPassword ? "text" : "password"}
        name={name}
        onChange={onChange}
        value={value}
      />
      <button 
        type="button"
        aria-label={showPassword ? "Hide" : "Show"}
        className='password-toggle' 
        onClick={handleToggleClick}>
        {showPassword ? 

        
        <VisibilityOffOutlined/> : <VisibilityOutlined/>
        }
      </button>
    </div>
  )
}

export default PasswordInput