import axios from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { DocumentType } from '../../utils/constants';
import { DataTable } from '../../components/DataTable/DataTable';
import { formatDate } from '../../utils/date.util';
import { useContext, useEffect, useState } from 'react';
import { createObjectMap, groupBy } from '../../utils/array.util';
import { DocumentsContext } from '../../context/DocumentsContext';
import { AccountsContext } from '../../context/AccountsContext';
import { UserContext } from "../../context/UserContext";
import { SelectionFilterGroup } from '../../components/SeletionFilterGroup/SelectionFilterGroup';
import { UIContext } from '../../context/UIContext';
import { handleTokenExpireError } from '../../utils/misc.util';
import { logUserAction, UserAction } from "../../utils/logging.util";

const buildFilterControlDef = (dataSource, accountsMap) => {
  const docs = dataSource.flatMap(data => data.children);
  const accountIds = [...new Set(docs.map(d => d.account_id))];
  const docTypeIds = [...new Set(docs.map(d => d.document_type_id))];
  const docTypeMap = createObjectMap(docs, "document_type_id", doc => doc.document_type_name);

  return {
    investmentId: {
      options: [{ id: 0, name: "All Investments" }, ...dataSource],
      valueFn: option => option.id,
      labelFn: option => option.name,
    },
    accountId: {
      options: [0, ...accountIds],
      labelFn: option => option ? accountsMap[option].account_name : "All Accounts"
    },
    documentTypeId: {
      options: [0, ...docTypeIds],
      labelFn: option => option ? docTypeMap[option] : "All Document Types"
    }
  }
}

const columnDef = [
  { id: "accountName", label: "ACCOUNT" },
  { id: "year", label: "YEAR", displayFn: data => data || "---"},
  { id: "document_type_name", label: "DOCUMENT TYPE" },
  { id: "fileLink", label: "DOCUMENT" },
  { id: "as_of_date", label: "AS OF", displayFn: v => formatDate(v, {dateStyle: "short"})},
]

export const DocumentsPage = () => {
  const {documents} = useContext(DocumentsContext);
  const {accountsMap} = useContext(AccountsContext);
  const {userData} = useContext(UserContext);
  const {setErrorMessage, setIsLoading} = useContext(UIContext);
  const [masterDataSource, setMasterDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [filterControlDef, setFilterControlDef] = useState({});
  const [filters, setFilters] = useState({
    investmentId: 0, 
    accountId: 0, 
    documentTypeId: 0,
  });

  useEffect(()=> {
    const groupedByCompany = groupBy(documents, doc => doc.investment_company_id);
    const dataSource = Object.entries(groupedByCompany).map(([companyId, group]) => ({
      id: companyId,
      name: group[0].investment_name,
      expandable: true,
      isInitiallyExpanded: false,
      children: group.map(doc => ({
        ...doc,
        accountName: accountsMap[doc.account_id]?.account_name || '---',
        fileLink: <a href="/" onClick={(e) => handleFileClick(e, doc.id)}>{doc.file_name.replace(/['"]+/g, "")}</a>
      })).sort((a,b) => b.created.localeCompare(a.as_of_date))
    }));

    setFilterControlDef(buildFilterControlDef(dataSource, accountsMap));
    setMasterDataSource(dataSource);
    logUserAction(userData.contact_id, UserAction.DocumentsPage);
  }, []);

  useEffect(() => {
    let dataSource = cloneDeep(masterDataSource);
    
    if (filters.investmentId) {
      dataSource = dataSource.filter(d => +d.id === filters.investmentId);
    }

    if (filters.accountId || filters.documentTypeId) {
      for (const data of dataSource) {
        data.children = data.children.filter(c => {
          const isAccount = !filters.accountId || c.account_id === filters.accountId;
          const isDocumentType = !filters.documentTypeId || c.document_type_id === filters.documentTypeId;
          return isAccount && isDocumentType;
        });
      }
    }

    dataSource = dataSource.filter(d => d.children.length);

    setDataSource(dataSource);
  },[masterDataSource, filters])

  const handleFileClick = async (event, id) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const link = (await axios.get(`/documents/download/${id}`)).data;
      window.open(link.url);
      logUserAction(userData.contact_id, UserAction.DownloadDocument, +id);
    } catch (error) {
      setErrorMessage("Error while downloading file");
      handleTokenExpireError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const filterGroupProps = {filters, setFilters, filterControlDef};

  return (
    <Container>
      <h1 style={{position: "relative", bottom: "1em", display:"inline"}}>Documents</h1>
      <Row>
        <Col>
          <DataTable
            dataSource={dataSource}
            columnDef={columnDef}
            headerComponent={<SelectionFilterGroup {...filterGroupProps}
            />}
          />
        </Col>
      </Row>
    </Container>
  )
}