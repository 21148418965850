import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { DocumentsContext } from "../../../context/DocumentsContext";
import { InvestmentsContext } from "../../../context/InvestmentsContext";
import { UserContext } from "../../../context/UserContext";
import { DocumentType } from "../../../utils/constants";
import { logUserAction, UserAction } from "../../../utils/logging.util";
import { ImportantDocuments } from "../../../components/ImportantDocuments/ImportantDocuments";
import "./InvestmentDetailsPage.scss";

export const InvestmentDetailsPage = () => {
  const { companyId } = useParams();
  const { investmentsByCompany } = useContext(InvestmentsContext);
  const { documents, entityPpms } = useContext(DocumentsContext);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const investments = investmentsByCompany[+companyId];
  const {investment_name, description} = investments[0];
  const {userData} = useContext(UserContext);

  useEffect(() => {
    const investorIds = investments.map(inv => inv.id);
    const docs = documents.filter(d => 
      d.document_type_id === DocumentType.GoverningDocuments && investorIds.includes(d.investor_id));

    const ppm = entityPpms[companyId];

    if (ppm) {
      docs.push({...ppm, document_type_id: DocumentType.PPM, document_type_name: "Private Placement Memorandum"});
    }

    setFilteredDocs(docs);
    logUserAction(userData.contact_id, UserAction.ViewInvestmentDetail, +companyId);
  }, [])
  

  return (
    <>
      <div className="bip-capital-page-header">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: "/investments"}}>Investments</Breadcrumb.Item>
          <Breadcrumb.Item active>{investment_name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bip-capital-investment-detail mb-5">
        <div className="bip-capital-investment-description">
          <h4>{investment_name}</h4>
          <p>{description}</p>
        </div>
        { !!filteredDocs.length && 
          <ImportantDocuments documents={filteredDocs}/>
        }
      </div>
    </>
  );
};