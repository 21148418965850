import React from "react";
import "./CardStack.scss";

export const CardStack = (props) => {
  const {children, className, title} = props;

  return (
    <section className={className ?? ""}>
      {!!title && <h5>{title}</h5>}
      <div className="bip-capital-cc-card-stack">
        {children}
      </div>
    </section>
  )
}