import React, { useState, createContext } from "react";

export const ShareholdersContext = createContext();

export const ShareholdersProvider = (props) => {
  const [shareholdersData, setShareholdersData] = useState(null);

  return (
    <ShareholdersContext.Provider value={{shareholdersData, setShareholdersData}}>
      {props.children}
    </ShareholdersContext.Provider>
  );
};
