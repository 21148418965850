import Arrows from './Arrow.scss';

const CarouselPrevArrow = ({ onClick }) => (
  <button className="arrow-button prev" onClick={onClick} aria-label="Previous Slide">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18l-6-6 6-6" />
    </svg>
  </button>
);

export default CarouselPrevArrow;
