import React, { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import InputLabel from "../../Inputs/InputLabel/InputLabel";
import TextInput from "../../Inputs/TextInput/TextInput";
import ErrorLabel from "../../Inputs/ErrorLabel/ErrorLabel";
import PrimaryButton from "../../Inputs/PrimaryButton/PrimaryButton";
import { TermsOfServiceUrl } from "../../../utils/misc.util";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .label("Old  Password")
    .min(8, "Password must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
      "Must contain minimum of one uppercase letter, lowercase letter, and special character"
    )
    .required(),
  newPassword: Yup.string()
    .label("New Password")
    .min(8, "Password must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
      "Must contain minimum of one uppercase letter, lowercase letter, and special character"
    )
    .required(),
});

export default function UpdatePasswordForm() {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const changePassword = async (oldPassword, newPassword, setSubmitting) => {
    try {
      setIsLoading(true);

      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);

      setIsLoading(false);

      alert("Success! Password successfully changed!");

      history.push("/settings");
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      alert("Oops! Something went wrong. Try again!");
    } finally {
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema,
    onSubmit(values, {setSubmitting}) {
      changePassword(values.oldPassword, values.newPassword, setSubmitting);
    },
    validateOnMount: true,
  });

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <InputLabel forWhich="oldPassword" value="Old Password" />
        <TextInput
          type="password"
          name="oldPassword"
          onChange={handleChange}
          value={values.oldPassword}
        />
        <ErrorLabel
          showError={touched.oldPassword && errors.oldPassword}
          error={errors.oldPassword}
        />

        <InputLabel forWhich="newPassword" value="New Password" />
        <TextInput
          type="password"
          name="newPassword"
          onChange={handleChange}
          value={values.newPassword}
        />
        <ErrorLabel
          showError={touched.newPassword && errors.newPassword}
          error={errors.newPassword}
        />

        <div className="Reset-btn-container">
          <PrimaryButton
            text="Submit"
            disabled={!isValid || isSubmitting}
            isLoading={isLoading}
          />
        </div>
      <div><small style={{fontSize: '11px'}}><a href={TermsOfServiceUrl}>Terms of Use and Disclosures</a></small></div>
      </form>
    </Fragment>
  );
}
