import React from "react";

import "./InputLabel.css";

const InputLabel = ({ forWhich, value }) => (
  <label htmlFor={forWhich} className="Input-label">
    {value}
  </label>
);

export default InputLabel;
