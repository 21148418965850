import { useEffect, useRef, useState } from "react";
import "./ExpandableParagraph.scss";

export const ExpandableParagraph = (props) => {
  const maxHeight = props.maxHeight ?? 88; // Default is just a magic number that's working for my first use case
  const [isExpanded, setIsExpanded] = useState(false);
  const [displayMoreToggle, setDisplayMoreToggle] = useState(false);
  const descriptionRef = useRef();

  useEffect(() => {
    const element = descriptionRef.current;
    const isOverflowing = element.scrollHeight > element.clientHeight;
    isOverflowing && setDisplayMoreToggle(true);

    const observer = new ResizeObserver(([entry]) => {
      const isExpanded = entry.target.classList.contains('expanded');
      const isOverflowing = entry.target.scrollHeight > maxHeight;
      const isShowMoreToggleRendered = !!entry.target.nextElementSibling;
      
      if (isOverflowing && !isShowMoreToggleRendered) {
        setDisplayMoreToggle(true);
      } else if (!isOverflowing && !isExpanded && isShowMoreToggleRendered) {
        setDisplayMoreToggle(false);
      } else if (!isOverflowing && isExpanded) {
        setIsExpanded(false);
        setDisplayMoreToggle(false);
      }
    })
    
    observer.observe(element); //TODO: Maybe able to get away with using a single observer for all instances and then calling 'unobserve' on the tear down. But may not need to use this component so not worrying about it now
    return () => observer.disconnect(); //Chrome should prevent memory leak, but unknown for other browsers so including teardown code
  }, [])

  return (
    <div className="bip-capital-expandable-paragraph">
      <div ref={descriptionRef} className={`expandable-content ${isExpanded ? "expanded" : ""}`} 
        style={isExpanded ? {maxHeight: "unset"} : {maxHeight: `${maxHeight}px`}}
      >
        {props.children}
      </div>
      {displayMoreToggle && 
        <div role="button" className="view-more" onClick={() => setIsExpanded(!isExpanded)}>
          View {isExpanded ? "Less" : "More"}
        </div>
      }
    </div>
  )
}