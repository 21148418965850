import React, { Fragment, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";

import Rectangle from "../../Views/Rectangle/Rectangle";
import Logo from "../../Logo/Logo";
import InputLabel from "../../Inputs/InputLabel/InputLabel";
import TextInput from "../../Inputs/TextInput/TextInput";
import ErrorLabel from "../../Inputs/ErrorLabel/ErrorLabel";
import PasswordInput from "../../Inputs/PasswordInput/PasswordInput";
import PrimaryButton from "../../Inputs/PrimaryButton/PrimaryButton";

import "./CompletePasswordResetForm.css";

const validationSchema = Yup.object().shape({
  passCode: Yup.string().label("SMS Code").length(6).required(),
  password: Yup.string()
    .label("Password")
    .min(8, "Password must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
      "Must contain minimum of one uppercase letter, lowercase letter, and special character"
    )
    .required(),
});

export default function CompletePasswordResetForm() {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useContext(UserContext);

  const changePassword = async (passCode, password, setSubmitting) => {
    try {
      setIsLoading(true);

      await Auth.forgotPasswordSubmit(userData.email, passCode, password);

      setIsLoading(false);

      alert("Success! Password successfully changed!");
      history.push("/login");
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      alert("Oops! Something went wrong. Try again!");
    } finally {
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      passCode: "",
      password: "",
    },
    validationSchema,
    onSubmit(values, {setSubmitting}) {
      changePassword(values.passCode, values.password, setSubmitting);
    },
    validateOnMount: true,
  });

  return (
    <Fragment>
      <Logo />
      <Rectangle>
        <form onSubmit={handleSubmit}>
          <p className="Complete-reset-title">Reset Password</p>

          <InputLabel forWhich="passCode" value="SMS Code" />
          <TextInput
            name="passCode"
            onChange={handleChange}
            value={values.passCode}
          />
          <ErrorLabel
            showError={touched.passCode && errors.passCode}
            error={errors.passCode}
          />

          <InputLabel forWhich="password" value="New Password" />
          <PasswordInput
            name="password"
            onChange={handleChange}
            value={values.password}
          />
          <ErrorLabel
            showError={touched.password && errors.password}
            error={errors.password}
          />
          <div className="Complete-reset-btn-container">
            <PrimaryButton
              text="Submit"
              disabled={!isValid || isSubmitting}
              isLoading={isLoading}
            />
          </div>
        </form>
      </Rectangle>
    </Fragment>
  );
}
