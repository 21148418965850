import "./NewsPageCard.scss";
import { formatDate } from "../../../utils/date.util";
import { Link } from "react-router-dom";

export const newsDateFormat = {
  year: "numeric",
  month: "numeric",
  day: "2-digit",
};

export const NewsPageCard = ({ news, className }) => {
  return (
    <div className={`bip-capital-news-page-card ${className ?? ""}`}>
      <img className="mr-3" src={news.logo_url} alt="placeholder"></img>
      <div className="news-info">
        <div className="news-date-line">
          <div className="news-chip">{news.name}</div>
          <span style={{ paddingLeft: "12px" }}>
            {formatDate(news.date, newsDateFormat)}
          </span>
        </div>
        <h4 className="mt-1">{news.title}</h4>
        <div className="news-update">{news.plain_updates}</div>
        <Link className="more-link" to={`/gpUpdates/${news.id}`}>
          Read more <i className="fas fa-chevron-right"></i>
        </Link>
      </div>
    </div>
  );
};
