import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ImportantDocuments } from "../../../components/ImportantDocuments/ImportantDocuments";
import { NewsContext } from "../../../context/NewsContext";
import { UIContext } from "../../../context/UIContext";
import { UserContext } from "../../../context/UserContext";
import { formatDate } from "../../../utils/date.util";
import { logUserAction, UserAction } from "../../../utils/logging.util";
import { newsDateFormat } from "../NewsPageCard/NewsPageCard";
import "./NewsDetail.scss";

export const NewsDetail = () => {
  const [attachments, setAttachments] = useState([]);
  const { newsId } = useParams();
  const { setIsLoading, setErrorMessage } = useContext(UIContext);
  const { userData } = useContext(UserContext);
  const { newsMap } = useContext(NewsContext);
  const n = newsMap[+newsId];

  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);
        const resp = await axios.get(`/news/${newsId}/attachments`);
        setAttachments(resp.data);
      } catch {
        setErrorMessage("Error while retrieving update attachments");
      } finally {
        setIsLoading(false);
      }
    };

    init();
    logUserAction(userData.contact_id, UserAction.ViewNewsDetail, +newsId);
  }, []);

  return (
    <Container className="bip-capital-news-detail">
      <div style={{ position: "relative", bottom: "1em", display: "inline" }}>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/gpUpdates" }}>
            GP Updates
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{n.title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row className="mb-4">
        <Col lg={9} md={6} className="news-header-column">
          <img src={n.logo_url} alt="logo" />
          <div className="news-header">
            <h2>{n.title}</h2>
            <div className="news-subheader">
              <div className="news-date-line">
                <span className="mr-4">
                  {formatDate(n.date, newsDateFormat)}
                </span>
                <span className="news-chip">{n.name}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={3} md={6}>
          {!!attachments.length && (
            <ImportantDocuments
              documents={attachments}
              title="ATTACHMENTS"
              listByFileName
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            dangerouslySetInnerHTML={{
              __html: n.isRichText ? n.updates_html : n.plain_updates,
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  );
};
