import React, { useState, createContext } from "react";

export const AccountsContext = createContext();

export const AppProvider = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [accountsMap, setAccountsMap] = useState({});

  return (
    <AccountsContext.Provider
      value={{
        accounts,
        setAccounts,
        selectedAccount,
        setSelectedAccount,
        accountsMap,
        setAccountsMap
      }}
    >
      {props.children}
    </AccountsContext.Provider>
  );
};
