import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  const { authenticated } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={() => (!authenticated ? <Component /> : <Redirect to="/" />)}
    />
  );
};

export default UnauthenticatedRoute;
