import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { EventsContext } from "../../context/EventsContext";
import { InvestmentsContext } from "../../context/InvestmentsContext";
import { CashFlowDetail } from "./CashFlowDetail/CashFlowDetail";
import { EventPageCard } from "./EventPageCard/EventPageCard";
import { UserContext } from "../../context/UserContext";
import { getEventTypeOptions, isEvent } from "./EventPage.util";
import { logUserAction, UserAction } from "../../utils/logging.util";
import "./EventsPage.scss";

export const EventsPage = () => {
  const { events, eventTypes } = useContext(EventsContext);
  const { upcomingCashFlows } = useContext(InvestmentsContext);
  const { userData } = useContext(UserContext);
  const [filters, setFilters] = useState({
    eventTypeId: 0,
    startDate: null,
    endDate: null,
  });

  const [masterList] = useState([...events, ...upcomingCashFlows]);

  const [filteredList, selectFilteredList] = useState(masterList);

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.EventsPage);
  }, []);

  useEffect(() => {
    const { eventTypeId, startDate, endDate } = filters;
    const [hasEventFilter, hasStartDateFilter, hasEndDateFilter] = [
      isNaN(+eventTypeId),
      !!startDate,
      !!endDate,
    ];

    if (!hasEventFilter && !hasStartDateFilter && !hasEndDateFilter) {
      selectFilteredList(masterList);
      return;
    }

    const list = masterList.filter((item) => {
      let isEventType = true;

      if (hasEventFilter) {
        const [type, idString] = eventTypeId.split("-");
        const isCashFlowType = type === "C";
        const typeIdKey = isCashFlowType ? "cash_flow_type_id" : "event_type";
        isEventType = item[typeIdKey] === +idString;
      }

      const dateKey = isEvent(item) ? "start_time" : "date";
      const eventDate = new Date(item[dateKey]);
      const isAfterStartDate =
        !hasStartDateFilter || eventDate.getTime() >= startDate.getTime();
      const isBeforeEndDate =
        !hasEndDateFilter || eventDate.getTime() <= endDate.getTime();

      return isEventType && isAfterStartDate && isBeforeEndDate;
    });
    selectFilteredList(list);
  }, [filters, masterList]);

  return (
    <Container className="bip-capital-events">
      <h1 style={{ position: "relative", bottom: "1em", display: "inline" }}>
        Events
      </h1>
      <Row>
        <Col lg="9" className="mt-0">
          <div className="bip-capital-events-filter-controls mb-3">
            <div className="bip-capital-select-wrapper">
              <Form.Control
                as="select"
                value={filters.eventTypeId}
                onChange={(e) =>
                  setFilters({ ...filters, eventTypeId: e.target.value })
                }
              >
                {getEventTypeOptions(eventTypes).map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.name}
                  </option>
                ))}
              </Form.Control>
            </div>
            <DatePicker
              selected={filters.startDate}
              onChange={(date) => setFilters({ ...filters, startDate: date })}
              maxDate={filters.endDate}
              placeholderText="Start Date"
            />
            <DatePicker
              selected={filters.endDate}
              onChange={(date) => setFilters({ ...filters, endDate: date })}
              minDate={filters.startDate}
              placeholderText="End Date"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="9">
          {filteredList.map((item, i) =>
            isEvent(item) ? (
              <EventPageCard key={i} event={item} className="mb-3" />
            ) : (
              <CashFlowDetail key={i} cashFlow={item} className="mb-3" />
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};
