import { Modal } from "react-bootstrap";

export const VideoPlayerModal = (props) => {
  const {srcUrl, showModal, handleHide} = props;

  return (
    <Modal
      show={showModal}
      onHide={handleHide}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      <video style={{width: "100%"}} controls preload="auto" autoPlay="true" controlsList="nodownload">
        <source src={srcUrl} type="video/mp4"></source>
      </video>
      </Modal.Body>
    </Modal>
  )
}