import {Link} from "react-router-dom";
import {formatDate} from "../../utils/date.util";
import {toCurrency, toPercent} from "../../utils/number.util";
import {HoverTip} from "../HoverTip/HoverTip";
import {MetricDefinition} from "../InvestmentsTable/InvestmentTable.util";

export const getBaseColumnDefs = () => {

  return [
    { 
      id: "account_name", 
      label: "ACCOUNT NAME", 
      displayFn: (value, row) => <Link to={`/investments/bdc/${row.investment_company_id}/position/${row.id}`}>{value}</Link> 
    },
    { id: "investment_name", label: "INVESTMENT NAME"},
    { id: "shares", label: "SHARES", displayFn: (data) => data?.toLocaleString() ?? "--" },
    { id: "price_per_share", label: "PRICE PER SHARE", displayFn: (data, row) => {
      const formattedValue = toCurrency(data, 2)
      const formattedAsOfDate = row.as_of_date ? formatDate(row.as_of_date, {dateStyle: 'short'}) : "";
      const toolTip = row.as_of_date ? `As of ${formattedAsOfDate}` : "As of date is not yet available.";

      return (
        <HoverTip tip={toolTip}>
          <span>{formattedValue}</span>
        </HoverTip>
      )
    }},
    { id: "nav", label: "NAV", displayFn: (data) => toCurrency(data, 2)},
    { 
      id: "irr", 
      label: (
        <span>
          IRR
          <HoverTip tip={MetricDefinition.IRR}><i className="fas fa-info-circle ml-1"></i></HoverTip>
        </span>
      ), 
      displayFn: (data, row) => {
        const hasIRRData = data !== null && data !== undefined;
        const value = hasIRRData ? toPercent(data, 1) : "---";
        return !!row && hasIRRData
          ? (
            <HoverTip tip={`As of ${formatDate(row.irr_as_of_date, {dateStyle: 'short'})}`}>
              <span>{value}</span>
            </HoverTip>
          )
          : value;
      }, 
      showTotal: true, 
    }
  ]
};