import React, { useState, createContext } from "react";

export const UserContext = createContext();

export const defaultUserData = {
  id: 1,
  email: "",
  first_name: "",
  investor_type: 1,
  last_name: "",
  phone: "",
}

export const AppProvider = (props) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [registrationStepOneData, setregistrationStepOneData] = useState({
    email: "",
    password: "",
    investorType: 1,
  });
  const [registrationStepTwoData, setregistrationStepTwoData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [userData, setUserData] = useState(defaultUserData);
  const [cognitoUser, setCognitoUser] = useState({});
  const [accreditations, setAccreditations] = useState([]);

  return (
    <UserContext.Provider
      value={{
        accreditations,
        setAccreditations,
        authenticated,
        setAuthenticated,
        userData,
        setUserData,
        registrationStepOneData,
        setregistrationStepOneData,
        registrationStepTwoData,
        setregistrationStepTwoData,
        cognitoUser,
        setCognitoUser
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
