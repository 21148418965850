import {useContext, useEffect, useState} from "react";

import axios from "axios";
import Container from "../../components/Views/Container/Container";
import Rectangle from "../../components/Views/Rectangle/Rectangle";
import {UIContext} from "../../context/UIContext";
import {UserContext} from "../../context/UserContext";
import {Form} from "react-bootstrap";
import InputLabel from "../../components/Inputs/InputLabel/InputLabel";
import PrimaryButton from "../../components/Inputs/PrimaryButton/PrimaryButton";
import {useHistory} from "react-router-dom";

export const UpdateAccreditation = () => {
  const history = useHistory();
  const {userData, setUserData, accreditations, setAccreditations} = useContext(UserContext);
  const {setErrorMessage, setSuccessMessage, isLoading, setIsLoading} = useContext(UIContext);
  const [selectedAccreditation, setSelectedAccreditation] = useState(userData.accreditation);

  useEffect(() => {
    if (accreditations.length) {
      return;
    }
    const init = async () => {
      setIsLoading(true);
      try {
        const resp = await axios.get('/users/accreditations');
        setAccreditations(resp.data.accreditations);
      } catch (e) {
        console.log(e)
        setErrorMessage('Error loading accreditations');
      } finally {
        setIsLoading(false);
      }

    }
    init();
  }, [accreditations.length, setAccreditations, setIsLoading, setErrorMessage]);

  const handleSubmit = async () => {
    if (!selectedAccreditation) {
      return;
    }
    
    try {
      const resp = await axios.patch(`/users/${userData.id}/accreditation`, {accreditationId: selectedAccreditation});
      setUserData(current => ({
        ...current,
        accreditation: resp.data.accreditation,
      }));
      history.push("/settings");
      setSuccessMessage('Accreditation updated.');
    } catch (e) {
      console.log(e);
      setErrorMessage('Error updating accreditation');
    }
  }

  return (
    <div className="settings-wrapper">
      <Container>
        <Rectangle>
          <p className="Settings-title">Update Accreditation</p>

          <div className="settings-content-container">
            <InputLabel forWhich="accreditation-select" value="Accreditation Status" />
            <Form.Control
              name='accreditation-select'
              as="select"
              value={selectedAccreditation}
              onChange={e => {
                setSelectedAccreditation(+e.target.value);
              }}
            >
              {accreditations.map((a, i) => <option key={i} value={a.id}>{a.name}</option>)}
            </Form.Control>
            <div className="Reset-btn-container">
              <PrimaryButton
                text="Submit"
                isLoading={isLoading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </Rectangle>
      </Container>
    </div>
  );
}
