import React, { useContext, useMemo, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { InvestmentsContext } from '../../context/InvestmentsContext';
import InvestmentInProgressCard from './InvestmentInProgressCard';
import CarouselPrevArrow from './CarouselPrevArrow';
import CarouselNextArrow from './CarouselNextArrow';
import './InvestmentsInProgress.scss';

const carouselSettings = {
  responsive: {
    desktop: { breakpoint: { max: 3000, min: 0 }, items: 2 },
  },
  swipeable: false,
  draggable: false,
  showDots: false,
  arrows: false,
  infinite: true,
  transitionDuration: 700,
  customTransition: "all 0.7s ease-in-out",
  itemClass: "carousel-item-padding",
};

export const InvestmentsInProgress = () => {
  const { interestList, bdcInterestList } = useContext(InvestmentsContext);
  const carouselRef = useRef(null);

  const combinedInterestList = useMemo(
    () => [
      ...interestList.map(inv => ({ ...inv, investmentType: 'RegD' })),
      ...bdcInterestList.map(inv => ({
        ...inv,
        commitment_amount: inv.subscription_amount,
        investmentType: 'BDC',
      })),
    ],
    [interestList, bdcInterestList]
  );

  const investmentsCount = combinedInterestList.length;
  const itemsPerSlide = 2;
  const showNavigation = investmentsCount > itemsPerSlide;

  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  if (investmentsCount === 0) {
    return null;
  }

  return (
    <div className="investment-carousel-container mt-4">
      <h3 className="carousel-header">Investment Opportunities</h3>
      <Carousel ref={carouselRef} {...carouselSettings}>
        {combinedInterestList.map((investment, index) => (
          <InvestmentInProgressCard investment={investment} index={index} investmentsCount={investmentsCount} key={investment.id || index} />
        ))}
      </Carousel>

      {showNavigation && (
        <div className="navigation-controls">
          <CarouselPrevArrow onClick={prevSlide} />
          <CarouselNextArrow onClick={nextSlide} />
        </div>
      )}       
    </div>
  );
};
