import axios from "axios";

export const WEB_PLATFORM_ID = 2;

export const UserAction = {
  LoggedIn: 1,
  DashboardPage: 2,
  InvestmentsPage: 3,
  ViewInvestmentDetail: 4,
  AccountsPage: 5,
  ViewAccountDetail: 6,
  EventsPage: 7,
  ViewEventDetail: 8,
  AddEventToCalendar: 9,
  ClickedEventSignUp: 10,
  DocumentsPage: 11,
  DownloadDocument: 12,
  DistributionInstructionsModal: 13,
  ViewDistributionInstructionDetail: 14,
  K1sPage: 15,
  DownloadAllK1s: 16,
  NewsPage: 17,
  ViewNewsDetail: 18,
  ViewSymposiumPage: 19,
  ViewSymposiumVideo: 20,
  K1ChecklistDownload: 21,
  ViewShareholdersPage: 22,
  ViewWaterMarkedDocument: 23,
  ViewOpportunitiesPage: 24,
  ViewOpportunityDetail: 25,
  IndicateInterestToInvest: 26,
  IndicateInterestToInvestBdc: 27,
}

export function logUserAction(contactId, actionId, actionDetailId, metadata) {
  const payload = {
    contact_id: contactId,
    user_platform_id: WEB_PLATFORM_ID,
    user_action_id: actionId
  };

  if (actionDetailId) {
    payload.user_action_detail_id = actionDetailId;
  }
  if (metadata) {
    payload.action_metadata = metadata;
  }
  const resp = axios.post("/userActionLogs", payload);
  return resp;
}

export function createNotificationLog(userActionLogId, customerId) {
  const payload = {
    user_action_log_id: userActionLogId,
    customer_id: customerId,
  };
  return axios.post("/notificationLogs", payload);
}