import React, { Fragment, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";

import { UserContext } from "../../../context/UserContext";

import Rectangle from "../../Views/Rectangle/Rectangle";
import Logo from "../../Logo/Logo";
import InputLabel from "../../Inputs/InputLabel/InputLabel";
import PasswordInput from "../../Inputs/PasswordInput/PasswordInput";
import ErrorLabel from "../../Inputs/ErrorLabel/ErrorLabel";
import PrimaryButton from "../../Inputs/PrimaryButton/PrimaryButton";

import "./SetPasswordForm.css";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .label("Password")
    .min(8, "Password must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
      "Must contain minimum of one uppercase letter, lowercase letter, and special character"
    )
    .required(),
});

export default function CompletePasswordResetForm({ setCurrentForm }) {
  const [isLoading, setIsLoading] = useState(false);
  const { cognitoUser, setCognitoUser } = useContext(UserContext);

  const completeNewPassword = async (password, setSubmitting) => {
    try {
      setIsLoading(true);

      const user = await Auth.completeNewPassword(cognitoUser, password);

      setCognitoUser(user);

      setIsLoading(false);

      setCurrentForm(2);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
      setIsLoading(false);
      alert("Oops! Something went wrong. Try again!");
    } finally {
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema,
    onSubmit(values, {setSubmitting}) {
      completeNewPassword(values.password, setSubmitting);
    },
    validateOnMount: true,
  });

  return (
    <Fragment>
      <Logo />
      <Rectangle>
        <form onSubmit={handleSubmit}>
          <p className="Set-password-title">Pick a Password</p>

          <InputLabel forWhich="password" value="New Password" />
          <PasswordInput
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password}
          />
          <ErrorLabel
            showError={touched.password && errors.password}
            error={errors.password}
          />

          <div className="Set-password-btn-container">
            <PrimaryButton
              text="Submit"
              disabled={!isValid || isSubmitting}
              isLoading={isLoading}
            />
          </div>
        </form>
      </Rectangle>
    </Fragment>
  );
}
