/**
 * Format a date to a string according to given INTL.DateTimeFormat options
 * See "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat"
 * @param {Date} date A date object or parsable javascript date string
 * @param {object} options (optional) INTL.DateTimeTime format options. Defaults to {dateStyle: 'medium'}
 * @returns {string}
 */
export function formatDate(date, options = {dateStyle: 'medium'}) {
  try {
    if (!date) {
      return "";
    }

    // Handle string input in YYYY-MM-DD format
    if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
      const [year, month, day] = date.split('-').map(Number);
      date = new Date(year, month - 1, day); // Month is zero-indexed in JS Date
    } else {
      date = date instanceof Date ? date : new Date(date);
    }

    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(date);

  } catch (error) {
    console.log(error);
  }
}

/** Returns a native Date set to local midnight for the current day  */
export function getCurrentDate() {
  const today = new Date();
  today.setHours(0,0,0,0);
  return today;
}

/**
 * Get a Date object that is some given hours in the future from a given date
 * @param {Date} date 
 * @param {number} hours 
 * @returns  {Date}
 */
export function getEndDateTime(date, hours) {
  const endDateTime = new Date(date);
  endDateTime.setHours(date.getHours() + hours);
  return endDateTime;
}