import React from "react";
import { Switch } from "react-router-dom";

import AuthenticatedRoute from "./components/Route/AuthenticatedRoute/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Route/UnauthenticatedRoute/UnauthenticatedRoute";

import LoginPage from "./pages/Login/LoginPage";
import PasswordResetPage from "./pages/PasswordReset/PasswordResetPage";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { Layout } from './components/Layout/Layout';

function App() {
  return (
    <Switch>
      <UnauthenticatedRoute path="/login" component={LoginPage} />
      <UnauthenticatedRoute path="/reset" component={PasswordResetPage} />
      <AuthenticatedRoute path="/" component={Layout} />
    </Switch>
  );
}

export default App;
