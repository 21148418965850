import React from "react";

import Container from "../../components/Views/Container/Container";
import Rectangle from "../../components/Views/Rectangle/Rectangle";
import UpdatePasswordForm from "../../components/Forms/UpdatePasswordForm/UpdatePasswordForm";

export default function UpdatePasswordPage() {
  return (
    <div className="settings-wrapper">
      <Container>
        <Rectangle>
          <p className="Settings-title">New Password</p>

          <div className="settings-content-container">
            <UpdatePasswordForm />
          </div>
        </Rectangle>
      </Container>
    </div>
  );
}
