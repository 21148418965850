import React, { Fragment, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";

import { UserContext } from "../../../context/UserContext";

import Rectangle from "../../Views/Rectangle/Rectangle";
import Logo from "../../Logo/Logo";
import InputLabel from "../../Inputs/InputLabel/InputLabel";
import TextInput from "../../Inputs/TextInput/TextInput";
import ErrorLabel from "../../Inputs/ErrorLabel/ErrorLabel";
import PrimaryButton from "../../Inputs/PrimaryButton/PrimaryButton";

import "./RequestPasswordResetForm.css";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
});

const RequestPasswordResetForm = ({ setPasscodeSent }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userData, setUserData } = useContext(UserContext);

  const resetPassword = async (email, setSubmitting) => {
    try {
      setIsLoading(true);

      const resp = await Auth.forgotPassword(email);

      if (resp.CodeDeliveryDetails.DeliveryMedium === "SMS") {
        setUserData({
          ...userData,
          email,
        });

        setIsLoading(false);

        setPasscodeSent(true);
      } else {
        setIsLoading(false);

        alert("Try Again. Email not found in system!");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      alert("Try Again. Email not found in system!");
    } finally {
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit(values, {setSubmitting}) {
      resetPassword(values.email, setSubmitting);
    },
    validateOnMount: true,
  });

  return (
    <Fragment>
      <Logo />
      <Rectangle>
        <form onSubmit={handleSubmit}>
          <p className="Reset-title">Reset Password</p>
          <InputLabel forWhich="email" value="Email" />
          <TextInput
            name="email"
            onChange={handleChange}
            value={values.email}
          />
          <ErrorLabel
            showError={touched.email && errors.email}
            error={errors.email}
          />
          <div className="Reset-btn-container">
            <PrimaryButton
              text="Submit"
              disabled={!isValid || isSubmitting}
              isLoading={isLoading}
            />
          </div>
        </form>
      </Rectangle>
    </Fragment>
  );
};

export default RequestPasswordResetForm;
