import React, { useState, createContext } from "react";

export const UIContext = createContext();

export const UIProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  return (
    <UIContext.Provider value={{
      isLoading, 
      setIsLoading, 
      errorMessage, 
      setErrorMessage,
      successMessage,
      setSuccessMessage
      }}>
      {props.children}
    </UIContext.Provider>
  );
};
