import { Card } from "react-bootstrap";
import ICalendarLink from "react-icalendar-link";
import { formatDate } from "../../utils/date.util";
import { EventSignUpButton } from "./EventSignUpButton/EventSignUpButton";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import "./EventCard.scss"

export const EventCard = (props) => {
  const {event} = props;
  const {userData} = useContext(UserContext);
  const startTime = formatDate(event.start_time, {timeStyle: "short"});
  const endTime  = formatDate(event.end_time, { hour: "numeric", minute: "numeric", timeZoneName: "short"});
  const calendarEvent = {
    title: event.name,
    description: event.description,
    startTime: event.start_time,
    endTime: event.end_time,
    location: event.location
  }

  return (
    <div className="bip-capital-cc-event-card">
      <Card>
        <Card.Body>
          <Card.Title>
            {formatDate(event.start_time, {month: "short", day: "2-digit"})} 
            <EventSignUpButton event={event}/>
          </Card.Title>
          <h6>{event.name}</h6>
          <div>{`${startTime} - ${endTime}`}</div>
          <div>{event.location}</div>
          <ICalendarLink filename="event.ics" event={calendarEvent}>
            <span onClick={() => logUserAction(userData.contact_id, UserAction.AddEventToCalendar, event.id)}>
              Add to Calendar
            </span>
          </ICalendarLink>
        </Card.Body>
      </Card>
    </div>
  )
}