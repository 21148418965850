import {Auth} from "aws-amplify";
import axios from "axios";
import {useContext, useEffect, useState} from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {defaultUserData, UserContext} from "../../context/UserContext";
import './UserAvatar.scss';

export const UserAvatar = () => {
  const {setCognitoUser, userData, setUserData, setAuthenticated} = useContext(UserContext);
  const [showPopover, setShowPopover] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Close the popover when the location changes
    setShowPopover(false);
  }, [location]);

  const handleSignOut = async (event) => {
    try {
      event.stopPropagation();
      event.preventDefault(); // For peace of mind; might not be needed
      await Auth.signOut();
      setCognitoUser({});
      setUserData(defaultUserData);
      setAuthenticated(false);
      axios.defaults.headers.common["accesstoken"] = "";
      localStorage.removeItem("userData");
    } catch (error) {
      alert("Error signing out"); //TODO Replace with more elegant error reporting UI
    }
  }

  const AvatarPopover = (
    <Popover id='avatar-popover' className="bip-capital-cc-user-avatar-popover">
      <Popover.Content>
        <h6>{`${userData.first_name} ${userData.last_name}`}</h6>
        <ul>
          <li><span style={{textDecoration: "underline"}}>{userData.email}</span></li>
          <li><Link to="/settings">Settings</Link></li>
          <li><Link to="/" onClick={handleSignOut}>Sign out</Link></li>
        </ul>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="bip-capital-cc-user-avatar">
      <OverlayTrigger 
        trigger="click" 
        placement="bottom-end" 
        overlay={AvatarPopover}
        show={showPopover}
        onToggle={(nextShow) => setShowPopover(nextShow)}
        rootClose
      >
        <button aria-label="toggle user options">
          Welcome, {`${userData.first_name}`}
          <i className="fas fa-user-circle"></i>
        </button>
      </OverlayTrigger>
    </div>
  )
}