import { useContext, useEffect, useState } from "react"
import { ChipToggleGroup } from "../ChipToggleGroup/ChipToggleGroup";
import { DataTable, MAX_ROWS } from "../DataTable/DataTable";
import { buildAccountDataSource, buildInvestmentDataSource, getBaseColumnDefs } from "./InvestmentTable.util";
import { InvestmentsContext } from "../../context/InvestmentsContext";
import { sumElements } from "../../utils/array.util";
import "./InvestmentsTable.scss";
import { formatDate } from "../../utils/date.util";

const chipList = [{label: "By Investment", value: false}, {label: "By Account", value: true}];

export const InvestmentsTable = (props) => {
  const {investments, accounts, className, companyId, accountId, accountsOnly, accountsMap, showByAccount, setShowByAccount } = props;
  const { investmentsByCompany, investmentsByAccount } = useContext(InvestmentsContext);
  const [accountDataSource, setAccountDataSource] = useState([]);
  const [investmentDataSource, setInvestmentDataSource] = useState([]);
  const [groupedInvestments, setGroupedInvestments] = useState();
  const [baseColumnDefs, setBaseColumnDefs] = useState(getBaseColumnDefs({dpi: investments.dpi, tvpi: investments.tvpi, efv: investments.total_efv}));

  useEffect(()=>{
    if (!companyId && !accountId) {
      setShowByAccount(!!accountsOnly);
      !accountsOnly && setInvestmentDataSource(buildInvestmentDataSource(investments.investments || [], investments.investment_irrs));
      setAccountDataSource(buildAccountDataSource(investments.investments ?? [], accounts));
      const totals = {dpi: investments.dpi, tvpi: investments.tvpi, efv: investments.total_efv, irr: investments.irr, committed_regD: investments.regD_committed};
      setBaseColumnDefs(getBaseColumnDefs(totals, !accountsOnly));
    } else {
      const groupedInvestments = companyId ? investmentsByCompany[+companyId] : investmentsByAccount[+accountId];
      const dataSource = companyId ?  buildAccountDataSource(groupedInvestments, accounts, true) : buildInvestmentDataSource(groupedInvestments, investments.investment_irrs);
      const totalPaid = sumElements(dataSource, 'paid');
      const totalDistributed = sumElements(dataSource, 'distributed');
      const totalEfv = sumElements(dataSource, 'efv');
      const irr = companyId ? investments.investment_irrs[+companyId] : accountsMap[+accountId].irr
      const totalDpi = totalDistributed / totalPaid;
      const totalTvpi = (totalDistributed + totalEfv) / totalPaid
      
      const totals = {
        paid: totalPaid,
        distributed: totalDistributed,
        efv: totalEfv,
        dpi: Number.isFinite(totalDpi) ? totalDpi : 0,
        tvpi: Number.isFinite(totalTvpi) ? totalTvpi : 0,
        irr,
        committed_regD: investments.regD_committed
      }
      setBaseColumnDefs(getBaseColumnDefs(totals, !!accountId));
      companyId ? setAccountDataSource(dataSource) : setInvestmentDataSource(dataSource);
      setShowByAccount(!!companyId);
      setGroupedInvestments(groupedInvestments);
    }
  }, [investments, accounts, companyId, accountId, investmentsByCompany, accountsOnly])
  
  const onChipClick = (_event, value) => {
    setShowByAccount(value);
    const totals = {dpi: investments.dpi, tvpi: investments.tvpi, efv: investments.total_efv, irr: investments.irr, committed_regD: investments.regD_committed};
    setBaseColumnDefs(getBaseColumnDefs(totals, !value));
  };

  const investmentColumnDefs = [{id: "name", label: "INVESTMENT"}, ...baseColumnDefs];
  const accountColumnDefs = [{id: "name", label: "ACCOUNT"}, ...baseColumnDefs];
  const [dataSource, columnDef] = showByAccount ? [accountDataSource, accountColumnDefs] : [investmentDataSource, investmentColumnDefs];

  return (
    <div className={className ?? ""}>
      <h5 className="mb-3">My {accountsOnly ? "Accounts" : "Investments"}</h5>
      <DataTable
        columnDef={columnDef}
        dataSource={dataSource}
        showTotalsFooter
        showPaginator={dataSource.length > MAX_ROWS}
        headerComponent={(!companyId && !accountId && !accountsOnly) && <ChipToggleGroup onClick={onChipClick} activeState={showByAccount} chipList={chipList}/>}
      />
      { !!companyId && !!groupedInvestments?.[0] &&
        <div className="mt-n3">
          <small className="mr-4">EFV as of {formatDate(groupedInvestments[0].value_as_of_date, {dateStyle: 'short'})}</small>
          <small className="mr-1">
            IRR as of {!!groupedInvestments[0].irr_as_of_date ? formatDate(groupedInvestments[0].irr_as_of_date, {dateStyle: 'short'}) : "N/A"}
          </small>
        </div>
      }
    </div>
  )
}