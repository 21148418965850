import toCurrency from "./TransformCurrency";

export {toCurrency}; // Should just refactor this but I guess I'm being lazy

export function toPercent(num, decimal = 0) {
  return (+num * 100).toFixed(decimal) + '%';
}

export function isNumber(num) {
  return !isNaN(num) && num !== Infinity && num !== null && num !==undefined
}

export function toMinutesSeconds(totalSeconds) {
  const minutesFraction = totalSeconds / 60;
  const minutes = Math.floor(minutesFraction);
  const seconds = Math.round(((minutesFraction) - minutes) * 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
}