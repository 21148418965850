import { CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem } from 'cdbreact';
import { NavLink } from 'react-router-dom';
import BIPLogo from '../../assets/BIPCV-horizontal-White.png';
import { TermsOfServiceUrl } from '../../utils/misc.util';
import "./SideNav.scss";

export const SideNav = ({routes}) => {

  return (
    <CDBSidebar textColor="#fff" backgroundColor="#ffffff24" className="bip-capital-cc-sidenav">
        <CDBSidebarHeader style={{padding: 0}} prefix={<span className="fa fa-chevron-left"></span>}>
          <img src={BIPLogo} alt="Logo" className="bip-capital-cc-sidenav-logo"/>
        </CDBSidebarHeader>

        <CDBSidebarContent>
          <CDBSidebarMenu>
          { routes.map(r => (
            <NavLink exact={r.navLinkExact} to={r.path} className="bip-capital-cc-sidenav-item" activeClassName="bip-capital-cc-sidenav-item-active" key={r.path}>
              <CDBSidebarMenuItem className={`${r.className || ""}`} icon={r.icon}>{r.label}</CDBSidebarMenuItem>
            </NavLink>
            ))
          }
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter className="text-center">
          <small style={{fontSize: '10px'}}><a href={TermsOfServiceUrl}>Terms of Use and Disclosures</a></small>
        </CDBSidebarFooter>
    </CDBSidebar>
  );
};