import React, { useState } from "react";

import Container from "../../components/Views/Container/Container";
import PasswordResetForm from "../../components/Forms/RequestPasswordResetForm/RequestPasswordResetForm";
import CompletePasswordResetForm from "../../components/Forms/CompletePasswordResetForm/CompletePasswordResetForm";

export default function LoginPage() {
  const [passcodeSent, setPasscodeSent] = useState(false);

  return (
    <div className="unauthenticate-wrapper">
      <Container>
        {!passcodeSent && (
          <PasswordResetForm setPasscodeSent={setPasscodeSent} />
        )}
        {passcodeSent && <CompletePasswordResetForm />}
      </Container>
    </div>
  );
}
