import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CashFlowsTable } from "../../components/CashFlowsTable/CashFlowsTable";
import { AggregateAccountDisclosure } from "../../components/Disclosure/AggregateAccountDisclosure";
import { PerformanceDisclosure } from "../../components/Disclosure/PerformanceDisclosure";
import { InvestmentsTable } from "../../components/InvestmentsTable/InvestmentsTable";
import { NewsCard } from "../../components/NewsCard/NewsCard";
import { AccountsContext } from "../../context/AccountsContext";
import { InvestmentsContext } from "../../context/InvestmentsContext";
import { NewsContext } from "../../context/NewsContext";
import { UserContext } from "../../context/UserContext";
import { logUserAction, UserAction } from "../../utils/logging.util";

export const AccountsPage = () => {
  const { investments } = useContext(InvestmentsContext);
  const { accounts, accountsMap } = useContext(AccountsContext);
  const { news } = useContext(NewsContext);
  const { userData } = useContext(UserContext);
  const [showByAccount, setShowByAccount] = useState(true);

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.AccountsPage);
  }, [])
  
  return (
    <Container fluid="md">
      <h1 className="bip-capital-page-header">Accounts</h1>
      <Row>
        <Col lg={9}>
          <InvestmentsTable
            className="mb-3"
            accountsOnly
            {...{investments, accounts, showByAccount, setShowByAccount}}
          />
          <div className="mt-n4 mb-3"><PerformanceDisclosure/></div>
          <CashFlowsTable
            investments={investments.investments ?? []}
            accounts={accounts}
            accountsMap={accountsMap}
          />
          <div className="mt-n3"><AggregateAccountDisclosure/></div>
        </Col>
        <Col lg={3}>
          {!!news.length && (
            <section className="bip-capital-cc-investments-news">
              <h5>
                GP Updates <Link to="/gpUpdates">See All</Link>
              </h5>
              {news.slice(0, 5).map((n, i) => (
                <NewsCard key={i} news={n} />
              ))}
            </section>
          )}
        </Col>
      </Row>
    </Container>
  );
};
