import "./VideoCard.scss";
import playIcon from "../../../assets/play-button.svg";
import { toMinutesSeconds } from "../../../utils/number.util";

export const VideoCard = ({video, handleThumbnailClick, className}) => {
  return (
    <div className={`bip-capital-video-card ${className ?? ""}`}>
      <div className="bip-capital-video-thumbnail-wrapper">
        <img className="thumbnail" src={video.thumbnail_url} alt={video.title}/>
        <button className="play-button" onClick={() => handleThumbnailClick(video)}>
          <img src={playIcon} alt="play"/>
        </button>
      </div>

      <section className="video-description">
        <h6 style={{display:"flex", justifyContent:"space-between"}}className="pt-1">{video.title} <i>{toMinutesSeconds(video.running_time_seconds)}</i></h6>
        <p><i>{video.speaker}</i></p>
        <p>{video.description}</p>
      </section>
    </div>
  )
}