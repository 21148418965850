import { Card } from "react-bootstrap";
import { ExpandableParagraph } from "../../../components/ExpandableParagraph/ExpandableParagraph";
import { CashFlow, CashFlowLabel } from "../../../utils/constants";
import { formatDate } from "../../../utils/date.util";
import { toCurrency } from "../../../utils/number.util";
import { ReactComponent as CapitalCallIcon } from "../../../assets/icon-capital-call.svg";
import { ReactComponent as DistributionIcon} from "../../../assets/icon-distribution.svg";
import "./CashFlowDetail.scss"

export const CashFlowDetail = (props) => {
  const {cashFlow, className} = props;
  const isCapitalCall = cashFlow.cash_flow_type_id === CashFlow.CapitalCall;
  const Icon = isCapitalCall ? CapitalCallIcon : DistributionIcon;

  return (
    <div className={`bip-capital-cash-flow-detail ${className}`}>
      <Card>
        <Card.Body>
          <Card.Title>
            <h6>
              <span>
                <Icon/>
                {formatDate(cashFlow.date, {dateStyle: "long"})}
              </span>
              <span>{CashFlowLabel[cashFlow.cash_flow_type_id]}</span>
            </h6> 
            <h4>
              <span>{cashFlow.title}</span>
              <span>{toCurrency(cashFlow.amount)}</span>
            </h4>
            <h6>{cashFlow.investmentName}</h6>
          </Card.Title>
          <ExpandableParagraph>{cashFlow.description}</ExpandableParagraph>
        </Card.Body>
      </Card>
    </div>
  )
}