import "./SymposiumPage.scss";
import { useContext, useEffect, useState } from "react"
import { VideosContext } from "../../context/VideosContext"
import { VideoCard } from "./VideoCard/VideoCard";
import { UIContext } from "../../context/UIContext";
import axios from "axios";
import { VideoPlayerModal } from "../../components/VideoPlayerModal/VideoPlayerModal";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { UserContext } from "../../context/UserContext";

export const SymposiumPage = () => {
  const { userData } = useContext(UserContext);
  const { videos } = useContext(VideosContext);
  const { setIsLoading } = useContext(UIContext);
  const [showPlayer, setShowPlayer, setErrorMessage] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.ViewSymposiumPage);
  }, [])
  

  const handleThumbnailClick = async (videoData) => {
    setIsLoading(true);
    try {
      const url = (await axios.post("/videos/url", {key: videoData.path})).data
      logUserAction(userData.contact_id, UserAction.ViewSymposiumVideo, videoData.id)
      setVideoUrl(url);
      setShowPlayer(true);
    } catch (error) {
      setErrorMessage("Encountered unexpected error while loading video. Please contact support.");
    } finally {
      setIsLoading(false);
    }
  }

  const handleHide = () => {
    setShowPlayer(false);
    setVideoUrl("");
  }

  return (
    <>
      <div className="bip-capital-symposium-page">
        <div className="video-grid">
          {videos.map(v => (
            <VideoCard video={v} key={v.title} {...{ handleThumbnailClick }} />
          ))}
        </div>
      </div>
      <VideoPlayerModal
        srcUrl={videoUrl}
        showModal={showPlayer}
        handleHide={handleHide}
      />
    </>
  )
}

