import { useState } from "react";
import { Modal } from "react-bootstrap";
import { DistributionInstructionDetail } from "./DistributionInstructionDetail/DistributionInstructionDetail";
import { DistributionInstructionTable } from "./DistributionInstructionTable/DistributionInstructionTable";



export const DistributionInstructionModal = (props) => {
  const {instructions, account, showModal, setShowModal} = props;
  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const hasInstructions = !!instructions.length;

  const handleHide = () => {
    setShowModal(false);
    setSelectedInstruction(null);
  }

  return (
    <Modal
      show={showModal}
      onHide={handleHide}
      centered
      size="lg"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!selectedInstruction ? 
            "Distribution Instructions" :
            <>
              <button onClick={() => setSelectedInstruction(null)} className="bip-capital-icon-button">
                <i className="fas fa-arrow-left mr-2"></i>
              </button>
              {selectedInstruction.name}
            </>

          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!selectedInstruction ? 
          <>
            <h6>
              {hasInstructions
                ? "Below are the distribution instructions linked to this account."
                : "No distribution instructions have been set for this account."
              }
            </h6>
            { hasInstructions && 
              <DistributionInstructionTable {...{account, instructions, setSelectedInstruction}}/>
            }
          </> :
          <DistributionInstructionDetail instruction={selectedInstruction}/>
        }
      </Modal.Body>
    </Modal>
  )
}