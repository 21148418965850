import React, { useState, createContext } from "react";

export const NewsContext = createContext();

export const NewsProvider = (props) => {
  const [news, setNews] = useState([]);
  const [newsMap, setNewsMap] = useState([]);

  return (
    <NewsContext.Provider value={{news, setNews, newsMap, setNewsMap}}>
      {props.children}
    </NewsContext.Provider>
  );
};
