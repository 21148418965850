import "./DashboardPage.scss";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { AccountsContext } from "../../context/AccountsContext";
import { InvestmentsContext } from "../../context/InvestmentsContext";
import { NewsContext } from "../../context/NewsContext";
import { OverviewCard } from "../../components/Views/OverviewCard/OverviewCard";
import { isNumber, toCurrency, toPercent } from "../../utils/number.util";
import { NewsCard } from "../../components/NewsCard/NewsCard";
import { CardStack } from "../../components/CardStack/CardStack";
import { CashFlowCard } from "../../components/CashFlowCard/CashFlowCard";
import { EventCard } from "../../components/EventCard/EventCard";
import { InvestmentsTable } from "../../components/InvestmentsTable/InvestmentsTable";
import { EventsContext } from "../../context/EventsContext";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { PerformanceDisclosure } from "../../components/Disclosure/PerformanceDisclosure";
import { DashboardPieChart } from "./DashboardPieChart/DashboardPieChart";
import { BdcInvestmentsTable } from "../../components/BdcInvestmentsTable/BdcInvestmentsTable";
import { InvestmentsInProgress } from "../../components/Carousel/InvestmentsInProgress";

export default function DashboardPage() {
  const { investments, upcomingCashFlows, investmentValueTotals, bdcInvestments } =
    useContext(InvestmentsContext);
  const { accounts } = useContext(AccountsContext);
  const { news } = useContext(NewsContext);
  const { upcomingEvents } = useContext(EventsContext);
  const { userData } = useContext(UserContext);
  const [showByAccount, setShowByAccount] = useState(false);

  const fundsVsInvestmentsData = {
    chartData: [
      { name: "BDC", value: investmentValueTotals.bdc, fill: "#7fadce" },
      { name: "Single Asset", value: investmentValueTotals.coInvestments, fill: "#f7f9f4",},
      { name: "Traditional Funds", value: investmentValueTotals.funds, fill: "#c1c4c7" },
    ],
    description: "Fair value of dollars invested into BDC vs. Single Asset vs. Traditional Funds",
    title: "Investment Allocation",
  };

  const equityVsDebtData = {
    chartData: [
      {
        name: "Equity Investments",
        value: investmentValueTotals.equity,
        fill: "#b8d9d1",
      },
      {
        name: "Debt Investments",
        value: investmentValueTotals.debt,
        fill: "#f7f9f4",
      },
    ],
    description:
      "Fair value of dollars invested into Equity Investments vs Debt Investments",
    title: "Equity vs. Debt",
  };

  const taxableVsAdvantagedData = {
    chartData: [
      {
        name: "Taxable",
        value: investmentValueTotals.taxable,
        fill: "#c1c4c7",
      },
      {
        name: "Tax-Advantaged",
        value: investmentValueTotals.taxAdvantaged,
        fill: "#f7f9f4",
      },
    ],
    description:
      "Fair value of dollars invested into Taxable Accounts vs Tax-Advantaged Accounts",
    title: "Taxable vs. Tax-Advantaged",
  };

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.DashboardPage);
  }, []);

  const OverviewRow = () => {
    const dpi = isNumber(investments.dpi) ? investments.dpi.toFixed(2) : "---";
    const tvpi = isNumber(investments.tvpi)
      ? investments.tvpi.toFixed(2)
      : "---";

    const cards = [
      {
        id: "totalEfv",
        label: "Estimated Fair Value",
        value: toCurrency(investments.total_efv ?? 0),
      },
      { id: "totalDpi", label: "Distributed to Paid-in Capital", value: dpi },
      { id: "totalTvpi", label: "Total Value to Paid-in Capital", value: tvpi },
      {
        id: "totalIrr",
        label: "Internal Rate of Return",
        value: investments.irr !== null ? toPercent(investments.irr, 1) : "---",
      },
    ];
    return (
      <>
        <Row>
          {cards.map((card) => (
            <Col key={card.id}>
              <OverviewCard {...card} />
            </Col>
          ))}
        </Row>
        <>
          <hr />
          <Row>
            <Col lg={4}>
              <DashboardPieChart data={fundsVsInvestmentsData} />
            </Col>
            <Col lg={4}>
              <DashboardPieChart data={equityVsDebtData} />
            </Col>
            <Col lg={4}>
              <DashboardPieChart data={taxableVsAdvantagedData} />
            </Col>
          </Row>
        </>
      </>
    );
  };

  return (
    <Container fluid="md" className="bip-capital-cc-dashboard">
      <OverviewRow />
      <InvestmentsInProgress />
      <Row className="mt-2">
        <Col lg={9}>
          <InvestmentsTable
            {...{ investments, accounts, showByAccount, setShowByAccount }}
          />
          { bdcInvestments.length > 0 && 
            <BdcInvestmentsTable bdcInvestments={bdcInvestments}/>
          }
          <div className="mt-n3">
            {!showByAccount && (
              <div>
                <small style={{ fontSize: "11px" }}>
                  Mouse over <em>EFV</em>, <em>IRR</em>, or <em>PRICE PER SHARE</em> row values to view As-Of dates.
                </small>
              </div>
            )}
            <PerformanceDisclosure />
          </div>

          <section className="mt-4">
            <h5>
              GP Updates
              <Link
                to="/gpUpdates"
                style={{ fontSize: "0.75em", marginLeft: "0.5rem" }}
              >
                See All
              </Link>
            </h5>
            <div className="bip-capital-cc-news-section mt-3">
              {news.slice(0, 5).map((n, i) => (
                <NewsCard key={i} news={n} />
              ))}
            </div>
          </section>
        </Col>
        <Col lg={3}>
          {!!upcomingCashFlows.length && (
            <CardStack title="Scheduled Capital Calls">
              {upcomingCashFlows.map((cf, i) => (
                <CashFlowCard cashFlow={cf} key={i} />
              ))}
            </CardStack>
          )}

          {!!upcomingEvents.length && (
            <CardStack title="Upcoming Events" className="mt-4">
              {upcomingEvents.map((e, i) => (
                <EventCard event={e} key={i} />
              ))}
            </CardStack>
          )}
        </Col>
      </Row>
    </Container>
  );
}
