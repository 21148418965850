import { DataTable, MAX_ROWS } from "../DataTable/DataTable";
import "./BdcInvestmentsTable.scss";
import { getBaseColumnDefs } from "./BdcInvestmentsTable.util";

export const BdcInvestmentsTable = (props) => {
  const {bdcInvestments, className } = props;
  const bdcColumnDef = getBaseColumnDefs();
  
  return (
    <div className={className ?? ""}>
      <h5 className="mb-3">My BDC Investments</h5>
      <DataTable
        columnDef={bdcColumnDef}
        dataSource={bdcInvestments}
        showPaginator={bdcInvestments.length > MAX_ROWS}
      />
    </div>
  )
}