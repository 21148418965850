import { useContext, useEffect } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ICalendarLink from "react-icalendar-link";
import { Link, useParams } from "react-router-dom";
import { EventSignUpButton } from "../../../components/EventCard/EventSignUpButton/EventSignUpButton";
import { EventsContext } from "../../../context/EventsContext";
import { UserContext } from "../../../context/UserContext";
import { formatDate } from "../../../utils/date.util";
import {logUserAction, UserAction} from "../../../utils/logging.util";
import "./EventDetail.scss";

export const EventDetail = (props) => {
  const { eventId } = useParams();
  const { eventsMap } = useContext(EventsContext);
  const { userData } = useContext(UserContext);
  const event = eventsMap[+eventId];

  const startTime = formatDate(event.start_time, {timeStyle: "short"});
  const endTime  = formatDate(event.end_time, { hour: "numeric", minute: "numeric", timeZoneName: "short"});
  const calendarEvent = {
    title: event.name,
    description: event.description,
    startTime: event.start_time,
    endTime: event.end_time,
    location: event.location
  };

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.ViewEventDetail, +eventId);
  }, [])

  return (
    <Container>
      <div style={{position: "relative", bottom: "1em", display:"inline"}}>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: "/events"}}>Events</Breadcrumb.Item>
          <Breadcrumb.Item active>{event.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row>
        <Col className="bip-capital-event-detail-page">
          <div className="mb-5">
            <h4 style={{display: "flex", justifyContent: "space-between"}}>
              {event.name}
              <EventSignUpButton event={event} className="sign-up-button"/>
            </h4>
            <h6>{formatDate(event.start_time, {dateStyle: "long"})}</h6>
            <h6>{`${startTime} - ${endTime}, ${event.location}`}</h6>
            <span></span>
            <ICalendarLink filename="event.ics" event={calendarEvent}>
              <span onClick={() => logUserAction(userData.contact_id, UserAction.AddEventToCalendar, event.id)}>
                Add to Calendar
                <i className="fas fa-calendar-alt ml-2"></i>
              </span>
            </ICalendarLink>
          </div>
          <h4>About</h4>
          <p>{event.description}</p>
        </Col>
      </Row>
    </Container>
  )
}