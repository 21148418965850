import { Link } from "react-router-dom";
import { formatDate } from "../../utils/date.util";
import "./NewsCard.css";

const backgroundImageFilter =
  "linear-gradient(to top, rgba(58, 58, 58, 0.64), rgba(0, 0, 0, 0.75) -100%)";

export const NewsCard = (props) => {
  const { news } = props;
  if (!news) {
    return false;
  }

  const backgroundImageStyle = {
    backgroundImage: `${backgroundImageFilter}, url(${news.logo_url})`,
  };

  return (
    <div className="bip-capital-cc-news-card" style={backgroundImageStyle}>
      <h5>{news.title}</h5>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h6 style={{ fontWeight: "bold" }}>{news.name}</h6>
        <span style={{ fontSize: "0.9em" }}>{formatDate(props.news.date)}</span>
        <Link
          to={`/gpUpdates/${news.id}`}
          style={{ paddingTop: "0.5rem", textDecoration: "unset" }}
        >
          Read more{" "}
          <span
            className="fa fa-chevron-right"
            style={{ marginLeft: "0.25rem" }}
          ></span>
        </Link>
      </div>
    </div>
  );
};
