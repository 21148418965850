import React, { Fragment, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";

import Rectangle from "../../Views/Rectangle/Rectangle";
import Logo from "../../Logo/Logo";
import InputLabel from "../../Inputs/InputLabel/InputLabel";
import TextInput from "../../Inputs/TextInput/TextInput";
import ErrorLabel from "../../Inputs/ErrorLabel/ErrorLabel";
import PrimaryButton from "../../Inputs/PrimaryButton/PrimaryButton";

import "./MFAForm.css";

const validationSchema = Yup.object().shape({
  passCode: Yup.string().label("SMS Code").length(6).required(),
});

export default function MFAForm({ origin }) {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const {
    setAuthenticated,
    registrationStepOneData,
    registrationStepTwoData,
    cognitoUser,
    setUserData,
    userData,
  } = useContext(UserContext);

  const verifyOtp = async (passCode, setSubmitting) => {
    try {
      setIsLoading(true);

      const userSession = await Auth.confirmSignIn(cognitoUser, passCode);

      const jwt = userSession.signInUserSession.accessToken.jwtToken;

      axios.defaults.headers.common["accesstoken"] = jwt;

      const user = await axios.post(`/users`, {
        cognito_id: userSession.username,
      });

      setUserData({
        ...user.data,
      });

      localStorage.setItem(
        "userData",
        JSON.stringify({
          cognito_id: userSession.username,
          ...userData,
          ...user.data,
          jwt,
        })
      );

      setIsLoading(false);

      setAuthenticated(true);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      alert("Try Again", "Invalid code");
    } finally {
      setSubmitting(false);
    }
  };

  const activateOtp = async (passCode, setSubmitting) => {
    try {
      setIsLoading(true);

      const { email, investorType } = registrationStepOneData;
      const { firstName, lastName, phoneNumber } = registrationStepTwoData;

      await Auth.confirmSignUp(email, passCode);

      await axios.post("/register", {
        firstName,
        lastName,
        email,
        investorType,
        phoneNumber,
      });

      setIsLoading(false);

      alert("Sign up Succesful! Please sign in with created user.");

      history.push("/login");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      alert("Try Again! Invalid code");
    } finally {
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      passCode: "",
    },
    validationSchema,
    onSubmit(values, {setSubmitting}) {
      if (origin === "login") {
        verifyOtp(values.passCode, setSubmitting);
      } else if (origin === "register") {
        activateOtp(values.passCode, setSubmitting);
      }
    },
  });

  return (
    <Fragment>
      <Logo />
      <Rectangle>
        <form onSubmit={handleSubmit}>
          <p className="Two-factor-authentication">
            Two-Factor <br /> Authentication
          </p>
          <InputLabel forWhich="passCode" value="SMS Code" />
          <TextInput
            name="passCode"
            onChange={handleChange}
            value={values.passCode}
          />
          <ErrorLabel
            showError={touched.passCode && errors.passCode}
            error={errors.passCode}
          />
          <div className="MFA-btn-container">
            <PrimaryButton
              text="Submit"
              disabled={!isValid || isSubmitting}
              isLoading={isLoading}
            />
          </div>
        </form>
      </Rectangle>
    </Fragment>
  );
}
