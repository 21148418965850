import { useContext } from 'react';
import { Card } from 'react-bootstrap';
import ICalendarLink from "react-icalendar-link";
import { Link } from 'react-router-dom';
import { EventSignUpButton } from '../../../components/EventCard/EventSignUpButton/EventSignUpButton';
import {EventsContext} from '../../../context/EventsContext';
import { UserContext } from '../../../context/UserContext';
import { formatDate } from '../../../utils/date.util';
import { logUserAction, UserAction } from '../../../utils/logging.util';
import "./EventPageCard.scss";

export const EventPageCard = ({event, className}) => {
  const {userData} = useContext(UserContext);
  const {eventTypesMap} = useContext(EventsContext);
  const startTime = formatDate(event.start_time, {timeStyle: "short"});
  const endTime  = formatDate(event.end_time, { hour: "numeric", minute: "numeric", timeZoneName: "short"});
  const calendarEvent = {
    title: event.name,
    description: event.description,
    startTime: event.start_time,
    endTime: event.end_time,
    location: event.location
  }
  
  return (
    <div className={`bip-capital-event-detail ${className}`}>
      <Card>
        <Card.Body>
          <Card.Title>
            <h6>
              <span>
                <i className="fas fa-calendar-alt"></i>
                {formatDate(event.start_time, {dateStyle: "long"})}
              </span>
              <span>{eventTypesMap[event.event_type].name}</span>
            </h6> 
            <h4>
              {event.name}
              <EventSignUpButton event={event} className="detail-signup-button"/>
            </h4>
            <h6>
              {`${startTime} - ${endTime}, ${event.location}`}
              <ICalendarLink filename="event.ics" event={calendarEvent}>
                <span onClick={() => logUserAction(userData.contact_id, UserAction.AddEventToCalendar, event.id)}>
                  Add to Calendar
                </span>
              </ICalendarLink>
            </h6>
          </Card.Title>
          <p className='event-card-description'>{event.description}</p>
          <Link to={`/events/${event.id}`}>View More</Link>
        </Card.Body>
      </Card>
    </div>
  )
}