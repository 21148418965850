import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

import "./index.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as FullStory from '@fullstory/browser';
import { AppProvider as UserProvider } from "./context/UserContext";
import { AppProvider as InvestmentsProvider } from "./context/InvestmentsContext";
import { AppProvider as AccountsProvider } from "./context/AccountsContext";
import { EventsProvider } from "./context/EventsContext";
import { NewsProvider } from "./context/NewsContext";
import { UIProvider } from "./context/UIContext";

import { Amplify } from "aws-amplify";
import { DocumentsProvider } from "./context/DocumentsContext";
import { ShareholdersProvider } from "./context/ShareholdersContext";
import { VideosProvider } from "./context/VideosContext";
import { OpportunitiesProvider } from "./context/OpportunitiesContext";
import {isProduction} from "./utils/misc.util";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

if (isProduction) {
  FullStory.init({ orgId: process.env.REACT_APP_FULL_STORY_ORG_ID});
}

ReactDOM.render(
  <UserProvider>
    <OpportunitiesProvider> 
        <InvestmentsProvider>
          <AccountsProvider>
            <NewsProvider>
              <EventsProvider>
                <DocumentsProvider>
                  <ShareholdersProvider>
                    <VideosProvider>
                      <UIProvider>
                        <React.StrictMode>
                          <BrowserRouter>
                            <App />
                          </BrowserRouter>
                        </React.StrictMode>
                      </UIProvider>
                    </VideosProvider>
                  </ShareholdersProvider>
                </DocumentsProvider>
              </EventsProvider>
            </NewsProvider>
          </AccountsProvider>
        </InvestmentsProvider> 
    </OpportunitiesProvider>
  </UserProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
