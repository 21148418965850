import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CashFlowsTable } from "../../../components/CashFlowsTable/CashFlowsTable";
import { PerformanceDisclosure } from "../../../components/Disclosure/PerformanceDisclosure";
import { DistributionInstructionModal } from "../../../components/DistributionInstructionModal/DistributionInstructionModal";
import { InvestmentsTable } from "../../../components/InvestmentsTable/InvestmentsTable";
import { NewsCard } from "../../../components/NewsCard/NewsCard";
import { AccountsContext } from "../../../context/AccountsContext";
import { InvestmentsContext } from "../../../context/InvestmentsContext";
import { NewsContext } from "../../../context/NewsContext";
import { UIContext } from "../../../context/UIContext";
import { UserContext } from "../../../context/UserContext";
import { logUserAction, UserAction } from "../../../utils/logging.util";
import { handleTokenExpireError } from "../../../utils/misc.util";

export const AccountDetail = () => {
  const { accountId } = useParams();
  const { investments, investmentsByAccount } = useContext(InvestmentsContext);
  const { accounts, accountsMap } = useContext(AccountsContext);
  const { news } = useContext(NewsContext);
  const { setIsLoading, setErrorMessage } = useContext(UIContext);
  const { userData } = useContext(UserContext);
  const [instructions, setInstructions] = useState([]);
  const [showDistributionInstructions, setShowDistributionInstructions] =
    useState(false);
  const account = accountsMap[+accountId];
  const [showByAccount, setShowByAccount] = useState(false);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/accounts/instructions/${accountId}`);
        setInstructions(response.data);
      } catch (e) {
        setErrorMessage("Unable to retrieve distribution instructions");
        handleTokenExpireError(e);
      } finally {
        setIsLoading(false);
      }
    };

    init();
    logUserAction(userData.contact_id, UserAction.ViewAccountDetail, +accountId);
  }, []);

  const handleInstructionClick = async (e) => {
    e.preventDefault();
    setShowDistributionInstructions(true);
    logUserAction(userData.contact_id, UserAction.DistributionInstructionsModal);
  };

  return (
    <Container fluid="md">
      <div className="bip-capital-page-header">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/accounts" }}>
            Accounts
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {account?.account_name || "---"}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row>
        <Col lg={9}>
          <InvestmentsTable
            className="mb-1"
            accountId={+accountId}
            {...{investments, accounts, accountsMap, showByAccount, setShowByAccount}}
          />
          <div className="mt-n3">
            <div><small style={{fontSize: '11px'}}>Mouse over <em>EFV</em> and <em>IRR</em> row values to view As-Of dates.</small></div>
            <PerformanceDisclosure/>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "1rem",
            }}
          >
            <a href="/#" onClick={handleInstructionClick}>
              View Distribution Instructions
            </a>
          </div>
          <CashFlowsTable
            investments={investmentsByAccount[+accountId] ?? []}
          />
        </Col>
        <Col lg={3}>
          {!!news.length && (
            <section className="bip-capital-cc-investments-news">
              <h5>
                GP Updates <Link to="/gpUpdates">See All</Link>
              </h5>
              {news.slice(0, 5).map((n, i) => (
                <NewsCard key={i} news={n} />
              ))}
            </section>
          )}
        </Col>
      </Row>

      <DistributionInstructionModal
        account={account}
        instructions={instructions}
        showModal={showDistributionInstructions}
        setShowModal={setShowDistributionInstructions}
      />
    </Container>
  );
};
