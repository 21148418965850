import { Link } from "react-router-dom";
import { groupBy, partition, sumElements } from "../../utils/array.util";
import { CashFlow } from "../../utils/constants";
import { formatDate } from "../../utils/date.util";
import { toCurrency, toPercent } from "../../utils/number.util";
import { HoverTip } from "../HoverTip/HoverTip";

export const MetricDefinition = {
  DPI: <span><strong>DISTRIBUTION TO PAID-IN</strong> (DPI) represents the total amount of cash paid to investors divided by the total contributed capital</span>,
  TVPI: <span><strong>TOTAL VALUE TO PAID-IN</strong> (TVPI) is a measure of total return of the fund including both cash paid to investors and the value of the remaining investments. TVPI is equal to cash distributions plus the estimated fair value of the remaining investments, divided by total capital contributed.</span>,
  EFV: <span><strong>ESTIMATED FAIR VALUE</strong> (EFV) is defined as the manager's best estimate of the market value of the investment</span>,
  IRR: <span><strong>THE INTERNAL RATE OF RETURN</strong> (IRR) is an annualized rate of return where the period is greater than one year. For investments that are less than one year, the IRR is not annualized. It is calculated using capital contributions and distributions from the beginning of the investment combined with the current fair value.</span>,
} 

export const getBaseColumnDefs = (totals, includeAsOfDates = false) => {
  const totalPaidFn = totals.paid ? () => totals.paid : null;
  const totalDistributedFn = totals.distributed ? () => totals.distributed : null;
  
  return [
    { id: "commitment", label: "COMMITMENT", displayFn: (data) => toCurrency(data), showTotal: true },
    { id: "paid", label: "PAID", displayFn: (data) => toCurrency(data), showTotal: true, totalFn: totalPaidFn },
    { id: "distributed", label: "DISTRIBUTED", displayFn: (data) => toCurrency(data), showTotal: true, totalFn: totalDistributedFn},
    { 
      id: "efv", 
      label: (
        <span>
          EFV
          <HoverTip tip={MetricDefinition.EFV}><i className="fas fa-info-circle ml-1"></i></HoverTip>
        </span>
      ), 
      displayFn: (data, row) => {
        return includeAsOfDates && row
          ? <HoverTip tip={`As of ${formatDate(row.efvDate, {dateStyle: 'short'})}`}><span>{toCurrency(data)}</span></HoverTip>
          : toCurrency(data) 
      }, 
      showTotal: true, 
      totalFn: () => totals.efv ?? "---" 
    },
    { 
      id: "dpi", 
      label: (
        <span>
          DPI
          <HoverTip tip={MetricDefinition.DPI}><i className="fas fa-info-circle ml-1"></i></HoverTip>
        </span>
      ),  
      displayFn: (data) => data.toFixed?.(2) ?? data, 
      showTotal: true, 
      totalFn: () => totals.dpi ?? "---" 
    },
    { 
      id: "tvpi", 
      label: (
        <span>
          TVPI
          <HoverTip tip={MetricDefinition.TVPI}><i className="fas fa-info-circle ml-1"></i></HoverTip>
        </span>
      ), 
      displayFn: (data) => data.toFixed?.(2) ?? data, 
      showTotal: true, 
      totalFn: () => totals.tvpi ?? "---" 
    },
    { 
      id: "irr", 
      label: (
        <span>
          IRR
          <HoverTip tip={MetricDefinition.IRR}><i className="fas fa-info-circle ml-1"></i></HoverTip>
        </span>
      ), 
      displayFn: (data, row) => {
        const hasIRRData = data !== null && data !== undefined;
        const value = hasIRRData ? toPercent(data, 1) : "---";
        return (includeAsOfDates && !!row && hasIRRData)
          ? (<HoverTip tip={`As of ${formatDate(row.irrDate, {dateStyle: 'short'})}`}>
              <span>{value}</span>
            </HoverTip>)
          : value;
      }, 
      showTotal: true, 
      totalFn: () => totals.irr 
    }
  ]
};

export const partitionCashFlows = (cashFlows)  => {
  return partition(
    cashFlows,
    (cf) => cf.cash_flow_type_id === CashFlow.CapitalCall,
    (cf) => cf.cash_flow_type_id === CashFlow.Distribution,
  );
}

export const buildInvestmentDataSource = (investments, investmentIrrs = null) => {
  const groupedByFund = groupBy(investments, i => i.investment_company_id);
  const mergedInvestments = [];

  for (const records of Object.values(groupedByFund)) {
    if (records.length === 1) {
      mergedInvestments.push(records[0]);
      continue;
    }
    const [first, ...rest] = records;
    const merged = rest.reduce((prev, curr) => {
      const commitment = prev.commitment + curr.commitment;
      const value = prev.value + curr.value; 
      const cash_flows = [...prev.cash_flows, ...curr.cash_flows];
      return { ...curr, commitment, value, cash_flows};
    }, first)
    mergedInvestments.push(merged);
  }

  const dataSource = mergedInvestments.map(i => {
    const cashFlows = i.cash_flows;
    const [capitalCalls, distributions] = partitionCashFlows(cashFlows);
    const callPaymentSum = sumElements(capitalCalls, "amount_paid");
    const distributedSum = sumElements(distributions, "amount");
    const irr = investmentIrrs ? investmentIrrs[i.investment_company_id] : i.irr;
    const dpi = distributedSum / callPaymentSum;
    const tvpi = (i.value + distributedSum) / callPaymentSum;
    
    return {
      id: i.id,
      name: <Link to={"/investments/" + i.investment_company_id.toString()}>{i.investment_name}</Link>,
      paid: callPaymentSum,
      distributed: distributedSum,
      efv: i.value,
      dpi: Number.isFinite(dpi) ? dpi : 0,
      tvpi: Number.isFinite(tvpi) ? tvpi : 0,
      irr,
      efvDate: i.value_as_of_date,
      irrDate: i.irr_as_of_date,
      commitment: i.commitment,
    }
  })
  return dataSource;
}

export const buildAccountDataSource = (investments, accounts, isByCompany = false) => {
  const investmentsByAccount = groupBy(investments, (i) => i.account_id);
  const accountsById = groupBy(accounts, (a) => a.id);
  const dataSource = [];

  for (const key in investmentsByAccount) {
    const investments = investmentsByAccount[key];
    const cashFlows = investments.flatMap((i) => i.cash_flows);
    const [capitalCalls, distributions] = partitionCashFlows(cashFlows);
    const callPaymentSum = sumElements(capitalCalls, "amount_paid");
    const distributedSum = sumElements(distributions, "amount");
    const valuationSum = sumElements(investments, "value");
    const irr = isByCompany ? investments[0].irr : accountsById[key]?.[0]?.irr;
    const dpi = distributedSum / callPaymentSum;
    const tvpi = (valuationSum + distributedSum) / callPaymentSum;
    const commitment = sumElements(investments, "commitment");

    const efvDate = investments.length > 0 ? investments[0].value_as_of_date : null;
    const irrDate = isByCompany
      ? investments[0].irr_as_of_date
      : accountsById[key]?.[0]?.irr_as_of_date;

    dataSource.push({
      id: key,
      name: (
        <Link to={"/accounts/" + key}>
          {accountsById[key]?.[0]?.account_name ?? "---"}
        </Link>
      ),
      paid: callPaymentSum,
      distributed: distributedSum,
      efv: valuationSum,
      dpi: Number.isFinite(dpi) ? dpi : 0,
      tvpi: Number.isFinite(tvpi) ? tvpi : 0,
      irr,
      irrDate: irrDate,
      efvDate: efvDate, 
      commitment: commitment,
    });
  }

  return dataSource;
};