import { useMemo } from "react";
import { DataTable } from "../../DataTable/DataTable";

const getColumnDef = (account, setSelectedInstruction) => [
  { id: "name", label: "Name", displayFn: (name, data) => <button className="btn btn-link bip-capital-link p-0" onClick={() => setSelectedInstruction(data)}>{name}</button> },
  { 
    id: "id", 
    label: "", 
    displayFn: (id) => id === account.distribution_instruction_id 
      ? <span style={{textDecoration: "underline", fontWeight: "bold"}}>Default</span>
    : ""},
  { id: "type", label: "Type"},
  { id: "account_number", label: "Account Number", displayFn: (n) => n || "" }
];

export const DistributionInstructionTable = ({instructions, account, setSelectedInstruction}) => {
  const columnDef = useMemo(() => getColumnDef(account, setSelectedInstruction), [account, setSelectedInstruction]);
  
  return (
    <DataTable
      columnDef={columnDef}
      dataSource={instructions} 
    />
  )
}