import { CashFlow, CashFlowLabel } from "../../utils/constants";

export const getEventTypeOptions = (eventTypes) => {
  const eventTypeOptions = eventTypes.map(e => ({id: `E-${e.id}`, name: e.name}));
  return [
    {id: 0, name: "All Event Types"},
    ...eventTypeOptions,
    {id: `C-${CashFlow.CapitalCall}`, name: CashFlowLabel[CashFlow.CapitalCall]},
    {id: `C-${CashFlow.Distribution}`, name: CashFlowLabel[CashFlow.Distribution]},

  ];
}

/** Returns whether the "event" item is an actual Event vs a CashFlow type of "event" */
export const isEvent = (item) => !!item.start_time;